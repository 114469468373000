import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from "./StandaloneServices.module.css";
import clsx from "clsx";
import {InputBase, Paper} from "@mui/material";
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import {useAppDispatch} from '../../redux/hooks';
import {changeEmailModalStatus} from '../../redux/models/modal';
import {StandaloneServicesData, standaloneServicesWithTitle} from "../../standaloneServicesWithTitle";
import Footer from "../Footer/Footer";
import ContactLight from "../ContactLigth/ContactLight";

const ENTER_KEY = 13;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `none`,
  marginBottom: "25px",
  '&:not(:last-child)': {
    borderBottom: "none",
  },
  '&:before': {
    display: 'none',
  },
  padding: "0",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    className={styles.sumIcons}
    expandIcon={<AddCircleIcon sx={{fontSize: '2rem', borderRadius: "35px"}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'transparent'
      : 'transparent',
  '& .MuiAccordionSummary-expandIconWrapper': {
    margin: 'auto 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  "@media screen and (max-width: 480px)": {
    justifyContent: "start",
    alignItems: "start",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: "36px 30px",
  background: "#fff",
  marginBottom: "5px",
  border: "none",
  borderRadius: "30px",
  display: "flex",
  flexDirection: 'column',
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  "@media screen and (max-width: 480px)": {
    flexWrap: "wrap",
    padding: "20px",
  },
}));

const StandaloneServices = () => {
  const [expanded, setExpanded] = React.useState<string>();
  const dispatch = useAppDispatch();

  const [filterText, setFilterText] = useState("");
  const [filteredArray, setFilteredArray] = useState<StandaloneServicesData[]>([]);

  const prefix = 'standalone';
  const handleChangeFilterText = useCallback((e: any) => {
    e.preventDefault();

    setFilterText(e.target.value.trim());
  }, []);

  const handleFilterArray = useCallback((e?: any) => {
    e?.preventDefault();
    setFilteredArray(standaloneServicesWithTitle.reduce((accum: StandaloneServicesData[], item) => {
      const titleMatch = item.title.toLocaleLowerCase().includes(filterText.toLowerCase().trim());

      if (titleMatch) {
        return [...accum, item]
      }

      const services: StandaloneServicesData['services'] = [];

      item.services.forEach((service) => {
        if (service.text.toLocaleLowerCase().includes(filterText.toLowerCase().trim())) {
          services.push(service);
        }
      })

      if (services?.length) {
        return [...accum, {...item, services}];
      }

      return accum;
    }, []));

  }, [setFilteredArray, filterText])

  const handlePressEnter = useCallback((e: any) => {
    if (e.which === ENTER_KEY) {
      handleFilterArray()
    }
  }, [handleFilterArray]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded('');

      setTimeout(() => {
        window.location.href = `${window.location.origin}#${panel}`;
        setExpanded(newExpanded ? panel : '');
      }, 500)
    };

  const handleOpenModal = useCallback(
    (title: string) => {
      dispatch(changeEmailModalStatus({
        isOpenEmail: true,
        metaEmail: {title, isIndividuals: true},
      }))
    },
    [dispatch],
  );


  useEffect(() => {
    handleFilterArray();
  }, [])

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      setExpanded(hash.slice(1))
    }
  }, [])

  return (
    <>
      <div className={styles.services_container}>
        {/*<div className={styles.services_top}>*/}
        {/*  <h1 className={clsx("h2_secondary", styles.services_title_secondary)}>*/}
        {/*    Платные услуги*/}
        {/*  </h1>*/}
        {/*</div>*/}
        <div className={styles.services_header}>
          <h1 className={clsx("h2_secondary", styles.services_title_secondary)}>
            Платные услуги
          </h1>
          <div className={styles.search_input}>
            <Paper
              component="form"
              sx={{display: 'flex', alignItems: 'center', maxWidth: "400px", width: "100%"}}
              className={styles.paper}
            >
              <InputBase
                sx={{ml: 3, mr: 3, flex: 1}}
                placeholder="Поиск..."

                className="text"
                onChange={handleChangeFilterText}
                onKeyDown={handlePressEnter}
              />
              <button
                className={styles.search_btn}
                onClick={handleFilterArray}
              >Искать
              </button>
            </Paper>
          </div>
        </div>
        <div className={styles.accordion}>
          {filteredArray.map(service => (
            <Accordion
              expanded={expanded === `${prefix}-${service.id}`}
              onChange={handleChange(`${prefix}-${service.id}`)}
              key={service.id}
            >
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <div className={styles.accordion_title_wrapper}>
                <span
                  className={styles.anchor}
                  id={`${prefix}-${service.id}`}
                />
                  <Typography className={styles.accordion_title}>{service.title}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{width: '100%'}}>
                  <div className={styles.accordion_list}>
                    {service.services?.map((option) => (
                      <div key={'option_' + option.id}>
                        <BoltIcon sx={{fontSize: '2rem', borderRadius: "35px"}}/>
                        <Typography>{option.text}</Typography>
                        <Typography
                          className={styles.option_dots}>·····························································································································································································································</Typography>
                        <Typography className={styles.option_price}>{option.price}</Typography>
                      </div>))}
                  </div>
                </div>
                <button onClick={handleOpenModal.bind(null, service.title)}
                        className={clsx("text_small", styles.price_div)}>
                  Получить услугу
                </button>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <ContactLight/>
      <Footer/>
    </>
  );
};

export default memo(StandaloneServices);
