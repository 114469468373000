const email = 'emfc.kbr07@gmail.com'

export const send = ({ name, phone, type = "Заявка", isIndividuals, registered }) => Email.send({
  Host : "smtp.elasticemail.com",
  Username : email,
  Password : "D689C62D84EDB9FAD7D49C57AE0CE55C6166",
  To : email,
  From : email,
  Subject : "Обратная связь",
  Body : `
  ${registered !== undefined ? ((!!registered) ? 'Является абонентом ЕМФЦ\n<br>' : 'Не является абонентом ЕМФЦ\n<br>') : ''}
  Тип обращения: ${isIndividuals ? 'Для физ. лиц' : 'Для юр. лиц'}\n<br>
  Вопрос: ${type}\n<br>
  Имя: ${name}\n<br>
  Телефон: ${phone}\n<br>
  `
});