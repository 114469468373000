import {memo, useCallback} from "react";
import styles from "./TripleRequest.module.css";
import clsx from "clsx";
import ambulance from "../../images/ambulance.png";
import papers from "../../images/papers.png";
import {changeNewsStatus} from "../../redux/models/main";
import {useAppDispatch} from "../../redux/hooks";

function TripleRequest() {
  const dispatch = useAppDispatch();

  const enableNewsStatus = useCallback(
      () => {
        dispatch(changeNewsStatus(true))
      },
      [dispatch],
  );

  return (
      <div id="triple_request" className={styles.triple_request}>
        <div className={styles.triple_request_flex}>
          <div className={clsx(styles.triple_request_box2, "boxes")}>
            <div id="news" className={styles.triple_request_box2_little}>
              <h2 className={clsx("h2", styles.triple_request_box1_title)}>Оставайтесь в курсе новостей!</h2>
              <p className={clsx("h2_des", styles.triple_request_box1_little_text)}>Мы издаём еженедельную газету с
                последними изменениями в
                законодательстве </p>
              <a href="#latest_news">
                <button
                    onClick={enableNewsStatus}
                    className={clsx(styles.triple_request_flex_btn_dark, "btn_title")}>К новостям
                </button>
              </a>
            </div>
            <img src={papers} alt="papers"/>
          </div>
        </div>
      </div>
  )
}

export default memo(TripleRequest);