import {forwardRef, memo, useCallback, useMemo, useState} from "react";
import styles from "./EmailModal.module.css";
import {Box, Dialog, Slide} from "@mui/material";
import close_logo from '../../images/close_logo.png';
import clsx from "clsx";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {changeEmailModalStatus} from "../../redux/models/modal";
import {TransitionProps} from "@mui/material/transitions";
import ContactDark from "../ContactDark/ContactDark";
import logo from "../../images/BlurLogo.png";
import video from "../../images/video.svg";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import { send } from "../../email.send";
import CloseIcon from '@mui/icons-material/Close';

const policy = require("../../files/policy.pdf");
const person = require("../../files/person.pdf");

interface IFormInput extends Record<string, unknown> {
  name: string;
  phone: string;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EmailModal() {
  const dispatch = useAppDispatch();
  const {register, control, handleSubmit, formState, setValue } = useForm<IFormInput>();
  const errors = useMemo(
      () => formState.errors,
      [formState]
  );

  const isOpenEmail = useAppSelector((state) => state.modal.isOpenEmail)
  const metaEmail = useAppSelector((state) => state.modal.metaEmail)

  const [isLoading, setIsLoading] = useState(false);

  const afterSendAction = () => {
    setValue('name', '');
    setValue('phone', '');
    setIsLoading(false);
  }

  const handleCloseModal = useCallback(
    () => {
      dispatch(changeEmailModalStatus({
        isOpenEmail: false,
        metaEmail: undefined,
      }))
    },
    [dispatch],
);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    
    send({ ...data, type: metaEmail?.title, isIndividuals: metaEmail?.isIndividuals } as any)
      .then(afterSendAction)
      .catch(afterSendAction)
      .finally(() => {
        handleCloseModal();
      });
  };


  return metaEmail ? (
      <Dialog
          fullScreen
          open={isOpenEmail}
          onClose={handleCloseModal}
          TransitionComponent={Transition}
      >
          <div className={styles.back_action} onClick={handleCloseModal}/>
          <div className={styles.contacts_first_flex}>
            <div className={styles.contacts_first_flex_box1}>
              <button className={styles.close_btn} onClick={handleCloseModal}><CloseIcon /></button>
              <h1 className="h2">{metaEmail.title}</h1>
              <p className="text">Для начала работы оставьте свои контактные данные. Наш специалист свяжется с вами в ближайшее
                время</p>
            </div>
            <form className={styles.contacts_first_flex_inputs} onSubmit={handleSubmit(onSubmit)}>
              <input
                  {...register("name", {required: true })}
                  className={clsx("text", styles.contacts_first_flex_inputs_info, errors.name && styles.error)}
                  type="text"
                  placeholder="Имя"
              />
              <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  render={({field}) => (
                      <InputMask
                          {...field}
                          mask="+7 (999) 999-99-99"
                          maskPlaceholder="="
                          className={clsx(styles.contacts_first_flex_inputs_info, "text", errors.phone && styles.error)}
                          type="tel"
                          placeholder="Телефон"
                      />
                  )}
              />
              <button
                  className={clsx(styles.contacts_first_flex_inputs_danger, "btn_title", isLoading && 'btn-disabled')}
                  type="submit"
                  disabled={isLoading}
              >
                Отправить
              </button>
            </form>
            <p className="text_xsmall">Нажимая кнопку «Отправить», Вы соглашаетесь с <a target="_blank" href={policy}>политикой
              конфиденциальности</a> и даете согласие на <a target="_blank" href={person}>обработку персональных данных</a></p>
          </div>        
      </Dialog>
  ) : <></>
}

export default memo(EmailModal);