import styles from './Cards.module.css';
import React, {memo, useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  CardsIndividual,
  CardsLegal,
  individualCards,
  legalCards,
  servicesForIndividuals,
  servicesForLegals
} from '../../utils';
import clsx from 'clsx';
import {changeActiveCard, changeStandaloneServicesStatus} from '../../redux/models/main';
import {changeEmailModalStatus} from "../../redux/models/modal";

interface ICardsProps {
  isIndividual: boolean;
}

function Cards({isIndividual}: ICardsProps) {
  const activeCard = useAppSelector((state) => state.main.activeCard);

  const cards = isIndividual ? individualCards : legalCards;
  const services = isIndividual ? servicesForIndividuals : servicesForLegals
  const dispatch = useAppDispatch();

  const getForCard = (card: CardsIndividual | CardsLegal, prevCard?: CardsIndividual | CardsLegal) => services.filter((service) => {
    const inService = service.forCards.includes(card);
    const isSkipped = !!prevCard && service.forCards.includes(prevCard)

    return inService && !isSkipped;
  })

  const countServices = (type: CardsIndividual | CardsLegal) => services.reduce((accum, item) => {
    if (item.forCards.includes(type)) {
      return accum + 1;
    }

    return accum;
  }, 0)

  const handleSelectCard = (card: CardsIndividual | CardsLegal) => (event: React.SyntheticEvent) => {
    event.stopPropagation();

    dispatch(changeActiveCard(card));
    window.location.href = `${window.location.origin}#${card}`;
  }

  const handleOpenModal = useCallback(
    (title: string) => {
      dispatch(changeEmailModalStatus({
        isOpenEmail: true,
        metaEmail: {title, isIndividuals: isIndividual},
      }))
    },
    [dispatch],
  );

  return (
    <div className={styles.cards_container} id={isIndividual ? 'individuals' : 'legals'}>
      {cards.map((card, index) =>
        <div className={clsx(styles.card, {[styles.cardActive]: card.name === activeCard})} key={card.name}>
          <span className={styles.anchor} id={card.name}/>
          <div className={styles.card_header}>
            <p className={styles.card_title}>{card.title}</p>
            <p className={styles.card_subtitle}>{card.subtitle} / {countServices(card.name)} услуг</p>
          </div>
          <div className={styles.card_content}>
            {/* {!!cards[index - 1] && <div className={styles.card_badge} onClick={handleSelectCard(cards[0].name)}>{`Все, что в тарифе ${cards[0].title}`}</div>} */}
            <div className={styles.card_services}>
              {getForCard(card.name).map((service) =>
                <p key={service.title} className={styles.card_service}>
                  <span className={styles.card_service_plus}>+</span>{service.title}
                </p>
              )}
            </div>
            <div className={styles.card_footer}>
              {`${card.cost}`}
            </div>
          </div>
          <div className={styles.card_underfooter}>
            <a onClick={handleOpenModal.bind(null, card.title)}><span
              className={styles.price_btn}>оплатить</span></a>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Cards);