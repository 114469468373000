import {memo} from "react";
import styles from "./PrizeCard.module.css";
import clsx from "clsx";
import presentBoxNew from "../../images/presentBoxNew.png";

const file = require("../../files/Правила розыгрыша.pdf");

function PrizeCard() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div id="news" className={styles.content}>
          <h2 className={clsx("h2", styles.title)}>Итоги розыгрыша</h2>
          <ul className={clsx("text_small", styles.description)}>
            <li>
              Итоги розыгрыша еженедельных призов для <b>абонентских карт "Прайм"</b> будут проведены каждое
              воскресенье, в
              21:00, в прямом эфире с помощью генератора случаных чисел.
            </li>
            <li>
              Итоги розыгрыша для <b>абонентских карт "Лайт"</b> будут проведены в 21:00, в прямом эфире с помощью
              генератора
              случаных чисел. Розыгрыш состоится, в честь 500 абонента по подписке "Лайт".
            </li>
            <li>
              Итоги розыгрыша для <b>абонентских карт "Оптима"</b> будут проведены в 21:00, в прямом эфире с помощью
              генератора
              случаных чисел. Розыгрыш состоится, в честь 500 абонента по подписке "Оптима".
            </li>
            <li>
              Итоги розыгрыша для <b>абонентских карт "Премиум"</b> будут проведены в 21:00, в прямом эфире с помощью
              генератора случаных чисел. Розыгрыш состоится, в честь 500 абонента по подписке "Премиум".
            </li>
          </ul>
          <a href={file} target="_blank">
            <button
              className={clsx(styles.button, "btn_title")}>Полные правила акции
            </button>
          </a>
        </div>
        <img className={styles.image} src={presentBoxNew} alt="presentBoxes"/>
      </div>
      <div className={styles.info_block}>
        <div className={clsx('text', styles.info_block_link)}>
          Справки по телефону
          <a href="tel:+78663040000">
            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="15"/>
              <path
                d="M8.70824 13.5804C10.3375 17.131 13.2366 19.954 16.841 21.4844L17.4187 21.742C18.7406 22.3313 20.2945 21.8835 21.1008 20.6811L21.8558 19.5551C22.1013 19.189 22.0265 18.6959 21.6836 18.419L19.123 16.3521C18.7469 16.0485 18.1939 16.1192 17.9061 16.5077L17.114 17.5772C15.0814 16.574 13.4312 14.9228 12.4285 12.8889L13.4974 12.0964C13.8856 11.8085 13.9563 11.2551 13.6529 10.8788L11.5871 8.31659C11.3105 7.97353 10.8178 7.8987 10.4519 8.14417L9.31877 8.90442C8.10953 9.71572 7.66457 11.2834 8.26711 12.6095L8.70758 13.579L8.70824 13.5804Z"
              />
            </svg>
            <span className="text">+ 7 (8663) 04-00-00</span>
          </a>
        </div>
        <div className={clsx('text', styles.info_block_text)}>Организатор акции ООО "Уорк Корнсалтинг Групп"; ИНН
          0700001095; ОГРН
          1210700006076;
          361401, КБР, Чегемский р-н, г.Чегем, ул. Героя России Кярова А.С., д.58;
        </div>
      </div>
    </div>
  )
}

export default memo(PrizeCard);