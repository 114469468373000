import {MetaProps} from "./redux/models/modal";

export interface CardData {
  cardTitle: string;
  cost: string;
  description: string;
  discount: string;
  cardBadge: string;
  bg: string;
  meta: MetaProps;
}

export enum CardsIndividual {
  premium = 'premium',
  optima = 'optima',
  lite = 'lite',
  prime = 'prime',
}

export enum CardsLegal {
  corp = 'corp',
  business = 'business',
  standart = 'standart',
  start = 'start',
}

export interface ServiceData {
  id: number;
  title: string,
  description: string;
  options: string[];
  tags: string[];
  forCards: (CardsIndividual | CardsLegal)[];
  price: string;
}

export const legals: CardData[] = [
  {
    cardTitle: "Стартовый",
    cost: "12 000 ₽",
    description: "5 видов услуг",
    discount: "10%",
    cardBadge: "год",
    bg: "start",
    meta: {
      title: "Тариф «Стартовый»",
      subTitle: ["1 год", "5 видов услуг", "12 000 ₽"],
      cards: [
        {
          title: "Юридический аудит деятельности организации/ ИП",
          text: `Юристы проведут аудит деятельности организации или ИП по всем 
          направлениям деятельности с подготовкой письменного заключения`,
          badge: "1"
        },
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "1"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для 
          представления ваших интересов `,
          badge: "∞"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по 
          любым правовым вопросам`,
          badge: "1"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений 
          нормативно - правового акта `,
          badge: "1"
        },
      ]
    }
  },
  {
    cardTitle: "Стандарт",
    cost: "60 000 ₽",
    description: "11 видов услуг",
    discount: "20%",
    cardBadge: "год",
    bg: "standart",
    meta: {
      title: "Тариф «Стандарт»",
      subTitle: ["1 год", " 11 видов услуг", "60 000 ₽"],
      cards: [
        {
          title: "Юридический аудит деятельности организации/ ИП",
          text: `Юристы проведут аудит деятельности организации или ИП по всем 
          направлениям деятельности с подготовкой письменного заключения`,
          badge: "1"
        },
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "12"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для 
          представления ваших интересов `,
          badge: "∞"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по 
          любым правовым вопросам`,
          badge: "2"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений 
          нормативно - правового акта `,
          badge: "6"
        },
        {
          title: "Обзор изменений законодательства",
          text: `Юрист проконсультирует вас по изменением действующего законодательства по вашему 
          виду деятельности`,
          badge: "1"
        },
        {
          title: "Представление интересов в Федеральной службе судебных приставов РФ",
          text: `Юристы обеспечат контроль при возбуждении исполнительного производства, обжалуют действия 
          (бездействия) приставов`,
          badge: "2"
        },
        {
          title: "Водоснабжение,  электоро-, тепло и газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "1"
        },
        {
          title: "Внесение изменений в учредительные документы",
          text: `Юристы подготовят необходимые документы и проведут регистрацию изменений в ЕГРЮЛ, 
          в учредительные и иные документы`,
          badge: "1"
        },
        // {
        //   title: "Письменные консультации",
        //   text: `Юрист проконсультирует вас по любим правовым вопросам (в т.ч. по земельным, налоговым и другим 
        //   имущественным спорам) путем подготовки и предоставления письменного заключения`,
        //   badge: "1"
        // },
        // {
        //   title: "Исковое производство",
        //   text: `Юристы подготовят исковые заявления (в т.ч. апелляционные, кассационные, надзорные жалобы)`,
        //   badge: "3"
        // }
      ]
    }
  },
  {
    cardTitle: "Бизнес",
    cost: "120 000 ₽",
    description: "14 видов услуг",
    discount: "30%",
    cardBadge: "год",
    bg: "business",
    meta: {
      title: "Тариф «Бизнес»",
      subTitle: ["1 год", "14 видов услуг", "120 000 ₽"],
      cards: [
        {
          title: "Юридический аудит деятельности организации/ ИП",
          text: `Юристы проведут аудит деятельности организации или ИП по всем 
          направлениям деятельности с подготовкой письменного заключения`,
          badge: "1"
        },
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "∞"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для 
          представления ваших интересов `,
          badge: "∞"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по 
          любым правовым вопросам`,
          badge: "4"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений 
          нормативно - правового акта `,
          badge: "12"
        },
        {
          title: "Обзор изменений законодательства",
          text: `Юрист проконсультирует вас по изменением действующего законодательства по вашему 
          виду деятельности`,
          badge: "4"
        },
        {
          title: "Представление интересов в Федеральной службе судебных приставов РФ",
          text: `Юристы обеспечат контроль при возбуждении исполнительного производства, обжалуют действия 
          (бездействия) приставов`,
          badge: "2"
        },
        {
          title: "Водоснабжение,  электоро-, тепло и газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "1"
        },
        {
          title: "Внесение изменений в учредительные документы",
          text: `Юристы подготовят необходимые документы и проведут регистрацию изменений в ЕГРЮЛ, 
          в учредительные и иные документы`,
          badge: "3"
        },
        // {
        //   title: "Письменные консультации",
        //   text: `Юрист проконсультирует вас по любим правовым вопросам (в т.ч. по земельным, налоговым и другим 
        //   имущественным спорам) путем подготовки и предоставления письменного заключения`,
        //   badge: "2"
        // },
        // {
        //   title: "Исковое производство",
        //   text: `Юристы подготовят исковые заявления (в т.ч. апелляционные, кассационные, надзорные жалобы)`,
        //   badge: "6"
        // },
        {
          title: "Представительство в судах",
          text: `Представление интересов в судах по арбитражным, гражданским, административным делам (в т.ч. участие в суде первой, апелляционной, 
          кассационной и надзорной инстанций)`,
          badge: "1"
        },
        {
          title: "Регистрационная и разрешительная деятельность",
          text: `Юрист проконсультирует вас по вопросу регистрации объектов (в т.ч. опасных и особо опасных), 
          оформлению, переоформлению лицензий`,
          badge: "1"
        },
        {
          title: "Представление интересов организации / ИП  при проверках проводимых контролирующими органами",
          text: `Юристы будут принимать участие при проверках проводимых органами госконтроля (МЧС, Ростехнадзор, 
          Роспотребнадзор, Росприроднадзор, Минприроды, Госстандарт и др.)`,
          badge: "1"
        }
      ]
    }
  },
  {
    cardTitle: "Корпоративный",
    cost: "180 000 ₽",
    description: "17 видов услуг",
    discount: "40%",
    cardBadge: "год",
    bg: "corp",
    meta: {
      title: "Тариф «Корпоративный»",
      subTitle: ["1 год", "17 видов услуг", "180 000 ₽"],
      cards: [
        {
          title: "Юридический аудит деятельности организации/ ИП",
          text: `Юристы проведут аудит деятельности организации или ИП по всем 
          направлениям деятельности с подготовкой письменного заключения`,
          badge: "∞"
        },
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "∞"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для 
          представления ваших интересов `,
          badge: "∞"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по 
          любым правовым вопросам`,
          badge: "12"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений 
          нормативно - правового акта `,
          badge: "∞"
        },
        {
          title: "Обзор изменений законодательства",
          text: `Юрист проконсультирует вас по изменением действующего законодательства по вашему 
          виду деятельности`,
          badge: "∞"
        },
        {
          title: "Представление интересов в Федеральной службе судебных приставов РФ",
          text: `Юристы обеспечат контроль при возбуждении исполнительного производства, обжалуют действия 
          (бездействия) приставов`,
          badge: "∞"
        },
        {
          title: "Водоснабжение,  электоро-, тепло и газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "∞"
        },
        {
          title: "Внесение изменений в учредительные документы",
          text: `Юристы подготовят необходимые документы и проведут регистрацию изменений в ЕГРЮЛ, 
          в учредительные и иные документы`,
          badge: "∞"
        },
        // {
        //   title: "Письменные консультации",
        //   text: `Юрист проконсультирует вас по любим правовым вопросам (в т.ч. по земельным, налоговым и другим 
        //   имущественным спорам) путем подготовки и предоставления письменного заключения`,
        //   badge: "∞"
        // },
        // {
        //   title: "Исковое производство",
        //   text: `Юристы подготовят исковые заявления (в т.ч. апелляционные, кассационные, надзорные жалобы)`,
        //   badge: "∞"
        // },
        {
          title: "Представительство в судах",
          text: `Представление интересов в судах по арбитражным, гражданским, административным делам (в т.ч. 
          участие в суде первой, апелляционной, 
          кассационной и надзорной инстанций)`,
          badge: "∞"
        },
        {
          title: "Регистрационная и разрешительная деятельность",
          text: `Юрист проконсультирует вас по вопросу регистрации объектов (в т.ч. опасных и особо опасных), 
          оформлению, переоформлению лицензий`,
          badge: "1"
        },
        {
          title: "Представление интересов организации / ИП  при проверках проводимых контролирующими органами",
          text: `Юристы будут принимать участие при проверках проводимых органами госконтроля (МЧС, Ростехнадзор, 
          Роспотребнадзор, Росприроднадзор, Минприроды, Госстандарт и др.)`,
          badge: "∞"
        },
        {
          title: "Составление проекта документа",
          text: `Юрист разработает под вашу ситуацию проект документа (договоров, приказов, претензий, ответов на 
          претензий, ходатайств и т.д)`,
          badge: "12"
        },
        {
          title: "Недвижимость",
          text: `Юрист проконсультирует вас по любым правовым вопросам оформления, владения, пользования 
          и распоряжения объектами недвижимости`,
          badge: "∞"
        },
        {
          title: "Оценка судебной перспективы",
          text: `Юрист подготовит заключение по результатам анализа  предоставленных документов, информации, 
          о перспективах судебного спора`,
          badge: "∞"
        }
      ]
    }
  }
]

export const individuals: CardData[] = [
  {
    cardTitle: "Прайм",
    cost: "200 ₽",
    description: "8 видов услуг",
    discount: "10%",
    cardBadge: "месяц",
    bg: "prime",
    meta: {
      title: "Тариф «Прайм»",
      subTitle: ["1 месяц", "8 видов услуг", "200 ₽"],
      cards: [
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "1"
        },
        {
          title: "Водоснабжение электоро-, тепло-, газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "1"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по любым правовым вопросам`,
          badge: "1"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений нормативно - правового акта`,
          badge: "1"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для представления ваших интересов`,
          badge: "∞"
        },
        {
          title: "Арест (счета, имущества)",
          text: `Юрист проверит основание наложения ареста на имущества или денежные средства и проконсультирует вас по правовым действиям`,
          badge: "1"
        },
        {
          title: "ЖКХ (жилищно-коммунальное хозяйство)",
          text: `Юрист проконсультирует вас по таким вопросам как написать жалобу, требовать компенсации, пересчитывать долги`,
          badge: "1"
        },
        {
          title: "Жалоба (в сфере ЖКХ)",
          text: `Юрист подготовит жалобу в органы и организации осуществляющих деятельность в сфере ЖКХ`,
          badge: "1"
        },
      ]
    }
  },
  {
    cardTitle: "Лайт",
    cost: "1 500 ₽",
    description: "11 видов услуг",
    discount: "20%",
    cardBadge: "год",
    bg: "lite",
    meta: {
      title: "Тариф «Лайт»",
      subTitle: ["1 год", " 11 видов услуг", "1 500 ₽"],
      cards: [
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "12"
        },
        {
          title: "Водоснабжение электоро-, тепло-, газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "6"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по любым правовым вопросам`,
          badge: "6"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений нормативно - правового акта`,
          badge: "2"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для представления ваших интересов`,
          badge: "∞"
        },
        {
          title: "Арест (счета, имущества)",
          text: `Юрист проверит основание наложения ареста на имущества или денежные средства и проконсультирует вас по правовым действиям`,
          badge: "2"
        },
        {
          title: "ЖКХ (жилищно-коммунальное хозяйство)",
          text: `Юрист проконсультирует вас по таким вопросам как написать жалобу, требовать компенсации, пересчитывать долги`,
          badge: "2"
        },
        {
          title: "Жалоба (в сфере ЖКХ)",
          text: `Юрист подготовит жалобу в органы и организации осуществляющих деятельность в сфере ЖКХ`,
          badge: "2"
        },
        {
          title: "Шаблоны документов",
          text: `Юрист предоставит вам шаблон (незаполненную форму) документов по вашему запросу`,
          badge: "1"
        },
        {
          title: "Штрафы",
          text: `Юрист проконсультирует вас по вопросу оспаривания привлечения к административной ответственности`,
          badge: "1"
        },
        {
          title: "Административное правонарушение",
          text: `Юрист проконсультирует вас в режиме онлайн при составлении протокола в отношении вас инспектором ДПС и другими органами контроля`,
          badge: "3"
        },
      ]
    }
  },
  {
    cardTitle: "Оптима",
    cost: "2 500 ₽",
    description: "15 видов услуг",
    discount: "30%",
    cardBadge: "год",
    bg: "optima",
    meta: {
      title: "Тариф «Прайм»",
      subTitle: ["1 год", "15 видов услуг", "2 500 ₽"],
      cards: [
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "∞"
        },
        {
          title: "Водоснабжение электоро-, тепло-, газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "12"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по любым правовым вопросам`,
          badge: "12"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений нормативно - правового акта`,
          badge: "4"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для представления ваших интересов`,
          badge: "∞"
        },
        {
          title: "Арест (счета, имущества)",
          text: `Юрист проверит основание наложения ареста на имущества или денежные средства и проконсультирует вас по правовым действиям`,
          badge: "4"
        },
        {
          title: "ЖКХ (жилищно-коммунальное хозяйство)",
          text: `Юрист проконсультирует вас по таким вопросам как написать жалобу, требовать компенсации, пересчитывать долги`,
          badge: "4"
        },
        {
          title: "Жалоба (в сфере ЖКХ)",
          text: `Юрист подготовит жалобу в органы и организации осуществляющих деятельность в сфере ЖКХ`,
          badge: "4"
        },
        {
          title: "Шаблоны документов",
          text: `Юрист предоставит вам шаблон (незаполненную форму) документов по вашему запросу`,
          badge: "3"
        },
        {
          title: "Штрафы",
          text: `Юрист проконсультирует вас по вопросу оспаривания привлечения к административной ответственности`,
          badge: "2"
        },
        {
          title: "Административное правонарушение",
          text: `Юрист проконсультирует вас в режиме онлайн при составлении протокола в отношении вас инспектором ДПС и другими органами контроля`,
          badge: "6"
        },
        {
          title: "Оформление документа",
          text: `Юрист разработает под вашу ситуацию проект документа`,
          badge: "2"
        },
        {
          title: "Права потребителя",
          text: `Юрист проконсультирует вас по вопросам защиты прав потребителя и порядке применения норм действующего законодательства`,
          badge: "2"
        },
        {
          title: "Недвижимость",
          text: `Юрист проконсультирует вас по любым правовым вопросам оформления, владения, пользования и распоряжения объектами недвижимости`,
          badge: "1"
        },
        {
          title: "Оценка судебной перспективы",
          text: `Юрист подготовит заключение по результатам анализа  предоставленных документов, информации, о перспективах судебного спора`,
          badge: "6"
        },
      ]
    }
  },
  {
    cardTitle: "Премиум",
    cost: "5 000 ₽",
    description: "18 видов услуг",
    discount: "40%",
    cardBadge: "год",
    bg: "premium",
    meta: {
      title: "Тариф «Стартовый»",
      subTitle: ["1 год", "18 видов услуг", "5 000 ₽"],
      cards: [
        {
          title: "Консультация юриста",
          text: `Юрист проконсультирует вас по любым правовым вопросам`,
          badge: "∞"
        },
        {
          title: "Водоснабжение электоро-, тепло-, газоснабжение",
          text: `Юрист дистанционно проконсультирует вас о порядке и способах оформления документов на электро-, 
          тепло-, газо-, и водоснабжения`,
          badge: "∞"
        },
        {
          title: "Звонок юриста от имени клиента",
          text: `Юрист от вашего имени и в ваших интересах переговорит по телефону с третьим лицом по любым правовым вопросам`,
          badge: "∞"
        },
        {
          title: "Разъяснение положений нормативно - правовых актов и документов",
          text: `Юрист разъяснит вам правовые последствия предоставленного документа или положений нормативно - правового акта`,
          badge: "6"
        },
        {
          title: "Нотариус, эксперт, адвокат",
          text: `Юрист предложит несколько организаций (экспертной/оценочной) или нотариуса, адвоката для представления ваших интересов`,
          badge: "∞"
        },
        {
          title: "Арест (счета, имущества)",
          text: `Юрист проверит основание наложения ареста на имущества или денежные средства и проконсультирует вас по правовым действиям`,
          badge: "6"
        },
        {
          title: "ЖКХ (жилищно-коммунальное хозяйство)",
          text: `Юрист проконсультирует вас по таким вопросам как написать жалобу, требовать компенсации, пересчитывать долги`,
          badge: "6"
        },
        {
          title: "Жалоба (в сфере ЖКХ)",
          text: `Юрист подготовит жалобу в органы и организации осуществляющих деятельность в сфере ЖКХ`,
          badge: "6"
        },
        {
          title: "Шаблоны документов",
          text: `Юрист предоставит вам шаблон (незаполненную форму) документов по вашему запросу`,
          badge: "∞"
        },
        {
          title: "Штрафы",
          text: `Юрист проконсультирует вас по вопросу оспаривания привлечения к административной ответственности`,
          badge: "∞"
        },
        {
          title: "Административное правонарушение",
          text: `Юрист проконсультирует вас в режиме онлайн при составлении протокола в отношении вас инспектором ДПС и другими органами контроля`,
          badge: "12"
        },
        {
          title: "Оформление документа",
          text: `Юрист разработает под вашу ситуацию проект документа`,
          badge: "4"
        },
        {
          title: "Права потребителя",
          text: `Юрист проконсультирует вас по вопросам защиты прав потребителя и порядке применения норм действующего законодательства`,
          badge: "4"
        },
        {
          title: "Недвижимость",
          text: `Юрист проконсультирует вас по любым правовым вопросам оформления, владения, пользования и распоряжения объектами недвижимости`,
          badge: "∞"
        },
        {
          title: "Оценка судебной перспективы",
          text: `Юрист подготовит заключение по результатам анализа  предоставленных документов, информации, о перспективах судебного спора`,
          badge: "∞"
        },
        // {
        //   title: "Письменные консультации",
        //   text: `Юрист проконсультирует вас по любим правовым вопросам путем подготовки и предоставления письменного заключения`,
        //   badge: "3"
        // },
        {
          title: "Составление налоговой декларации",
          text: `Юрист подготовит и направит вам налоговую декларацию по налогу на доходы физических лиц (форма 3-НДФЛ)`,
          badge: "1"
        },
        {
          title: "«Моя семья» – консультации членов семьи клиента по своим личным вопросам",
          text: `Устные консультации в рамках подписка может получить не только сам клиент, но и член его семьи (муж/жена , дети, родители)`,
          badge: "12"
        },
      ]
    }
  }
]

// services' data

export const servicesForLegals: ServiceData[] = [
  {
    id: 1,
    title: 'Устные консультации юриста онлайн',
    description: '',
    options: [
      'Онлайн консультация',
    ],
    tags: [],
    forCards: [CardsLegal.start, CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 2,
    title: 'Аудит промышленной безопасности онлайн',
    description: '',
    options: [
      'Проверка выполнения организацией требовании промышленной безопасности',
      'Проверка эксплуатационных документов',
      'Проверка документации на технические устройства',
      'Проверка документов связанных с эксплуатацией опасных производственных объектов'
    ],
    tags: [],
    forCards: [CardsLegal.start, CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 3,
    title: 'Регистрация опасных производственных объектов в Ростехнадзоре онлайн',
    description: '',
    options: [
      'Постановка на учет онлайн',
      'Внесение сведении в реестр ОПО',
    ],
    tags: [],
    forCards: [CardsLegal.start, CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 4,
    title: 'Подготовка жалобы на постановления административных органов онлайн',
    description: '',
    options: [
      'Подача жалобы на решение, действие/бездействие Ростехнадзора, МЧС, Роспотребнадзора, МВД, Росприроднадзора',
    ],
    tags: [],
    forCards: [CardsLegal.start, CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 5,
    title: 'Консультация адвоката по уголовным делам онлайн',
    description: '',
    options: [
      'Онлайн консультация',
      'Удобно',
    ],
    tags: [],
    forCards: [CardsLegal.start, CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 6,
    title: 'Услуги по возврату долгов (выкуп долгов, финансирование судебных споров)',
    description: '',
    options: [
      'Бесплатная оценка перспективы дела по возврату долга онлайн',
      'Онлайн изучение документов',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 7,
    title: 'Юридический анализ (юридическая экспертиза) документов онлайн',
    description: '',
    options: [
      'Онлайн изучение документов',
      'Подготовка заключения',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 8,
    title: 'Регистрация ООО, ИП, внесение изменений в учредительные документы',
    description: '',
    options: [
      'Онлайн подготовка документов',
      'Быстро, удобно',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 9,
    title: 'Займы, кредиты для бизнеса (сопровождение и помощь в получении)',
    description: '',
    options: [
      'Онлайн сопровождение и подбор кредитных организации',
      'Проверка кредитных договоров/договоров займа',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 10,
    title: 'Составление претензии (отзыва на претензию ответчика/истца)',
    description: '',
    options: [
      'Онлайн составление претензии',
      'Дистанционное изучение документов',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 11,
    title: 'Обжалование постановлении службы судебных приставов онлайн',
    description: '',
    options: [
      'Онлайн подготовка жалобы на действия/бездействия приставов ',
    ],
    tags: [],
    forCards: [CardsLegal.standart, CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 12,
    title: 'Подготовка заявлении о выдаче судебного приказа (По суммам до 500 тыс. руб.)',
    description: '',
    options: [
      'Подготовка заявлении на выдачу судебного приказа '
    ],
    tags: [],
    forCards: [CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 13,
    title: 'Сопровождение исполнительного производства',
    description: '',
    options: [
      'Переговоры с приставами',
      'Контроль за взысканием ',
    ],
    tags: [],
    forCards: [CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 14,
    title: 'Сбор и подготовка документов в области промышленной безопасности',
    description: '',
    options: [
      'Сбор и анализ необходимых документов',
      'Подготовка комплекта документов в области промышленной безопасности',
    ],
    tags: [],
    forCards: [CardsLegal.business, CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 15,
    title: 'Лизинг автомобиля',
    description: 'Лизинг автомобиля – долгосрочная аренда, эффективная схема финансирования действующего бизнеса и компаний в стадии развития.',
    options: [
      'Позволяет сохранить оборотные средства',
      'Оптимизация налогообложения',
      'Возможность расторгнуть договор в любой момент'
    ],
    tags: [
      'лизинг автомобиля для юридических',
      'лизинг бу автомобилей',
      'лизинг продажа автомобилей'
    ],
    forCards: [CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 16,
    title: 'Лизинг оборудования',
    description: 'Лизинг оборудования – финансовая аренда для увеличения прибыли вашего бизнеса за счет модернизации или механизации технологических процессов.',
    options: [
      'Замена старого оборудования с минимальными вложениями',
      'Увеличение объема продаж',
      'Возможность вернуть НДС'
    ],
    tags: [
      'лизинг оборудования',
      'оборудование лизинг аренда',
      'договор лизинга оборудования'
    ],
    forCards: [CardsLegal.corp],
    price: 'в рамках подписки',
  },
  {
    id: 17,
    title: 'Инвестиции в бизнес',
    description: 'Финансирование бизнеса – сервис, ориентированный на привлечение инвестиций в бизнес-идеи, поддержку и развитие действующего бизнеса.',
    options: [
      'Поиск и выбор надежного инвестора',
      'Выгодные условия для всех сторон',
      'Консультация по возможным стратегиям развития бизнеса',
    ],
    tags: [
      'инвесторы для бизнеса',
      'как найти инвестора для бизнеса',
      'ищу инвестора для бизнеса',
    ],
    forCards: [CardsLegal.corp],
    price: 'в рамках подписки',
  },
];

export const servicesForIndividuals: ServiceData[] = [
  {
    id: 1,
    title: 'Устные консультации юриста онлайн ',
    description: 'Удобный формат встречи по видеосвязи, который экономит время и затраты на дорогу.',
    options: [
      'Бесплатная консультация в любое время',
      'Откуда вам удобно',
      'Решение вашего вопроса в максимально возможные сроки',
    ],
    tags: [
      'юрист онлайн консультация',
      'юрист онлайн консультация бесплатно',
      'круглосуточные юристы онлайн'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 2,
    title: 'Подготовка заявлении об отмене судебного приказа онлайн',
    description: 'Подготовим документы в помощь гражданам, которым необходимо отменить судебный приказ.',
    options: [
      'Быстро',
      'Удобный онлайн-формат ',
      'Инструкция по дальнейшим действиям',
    ],
    tags: [
      'юридическая помощь в подготовке документов',
      'подготовка документов для регистрации юридических лиц',
      'подготовить документы'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 3,
    title: 'Подготовка заявления на возврат товара онлайн',
    description: 'Правильное оформление заявления о возврате товара.',
    options: [
      'Устная консультация',
      'Оформление заявления',
      'Инструкция по дальнейшим действиям'
    ],
    tags: [
      'семейный адвокат',
      'адвокат по семейным делам',
      'хороший семейный адвокат'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 4,
    title: 'Консультация адвоката по уголовным делам онлайн',
    description: 'ЕМФЦ сотрудничает с адвокатскими палатами во многих регионах. Даже если вы живете в другом городе, мы подберем вам опытного адвоката для представления интересов и защиты прав с гарантией результата.',
    options: [
      'Большая база проверенных специалистов в разных регионах',
      'Вам не придется тратить время на поиск надёжного адвоката',
      'Выступаем посредниками и гарантируем результат'
    ],
    tags: [
      'найти адвоката',
      'где найти адвоката',
      'адвокатские услуги'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 5,
    title: 'Подготовка жалобы на постановление ГИБДД онлайн',
    description: 'Поможем подготовить жалобу на отмену постановлений ГИБДД онлайн.',
    options: [
      'Устная консультация',
      'Оформление жалобы',
      'Помощь в отправке в ГИБДД'
    ],
    tags: [
      'выбрать карту банка',
      'кредит выбрать банки',
      'какие банки выбрать'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 6,
    title: 'Помощь по страховым спорам',
    description: 'Быстро и по минимальной цене оформим любой вид страхования: ипотечное, страхование жизни, гражданской ответственности, автострахование.',
    options: [
      'Экономия времени на поиске страховой компании и заполнении всех документов',
      'Подбор выгодных условия страхования конкретно под ваш запрос',
      'Представление ваших интересов в страховой компании'
    ],
    tags: [],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 7,
    title: 'Юридическая помощь по банкротству',
    description: 'Наши юристы проведут консультацию по банкротству физического лица бесплатно. Ответим на Ваши вопросы по банкротству. Выполним процесс банкротства без рисков.',
    options: [
      'Быстро',
      'Гарантированно',
      'Удобный онлайн-формат'
    ],
    tags: [
      'турпоездка',
      'куда поехать отдыхать',
      'отдых недорого'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 8,
    title: 'Услуги по возврату долгов (выкуп долгов, финансирование судебных споров)',
    description: '',
    options: [
      'Анализ должника',
      'Первичная консультация',
      'Удобный онлайн формат'
    ],
    tags: [
      'продать вещи',
      'где продать вещи',
      'скупка одежды'
    ],
    forCards: [CardsIndividual.prime, CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 9,
    title: 'Юридический анализ (юридическая экспертиза документов онлайн)',
    description: '',
    options: [
      'Установление конкретного нарушения того или иного законодательного акта в исследуемом документе',
      'Анализ отдельных пунктов договора, касающихся порядка расчетов, прав и обязанностей',
      'Проверки предоставленных документов на соответствие нормативным актам',
    ],
    tags: [
      'как составить резюме',
      'собеседование на работу',
      'найти работу'
    ],
    forCards: [CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 10,
    title: 'Направление адвокатских запросов',
    description: 'Адвокат вправе направлять в органы государственной власти, органы местного самоуправления, общественные объединения и иные организации в порядке, установленном Федеральным законом.',
    options: [
      'Запрос на бумажном носителе',
      'Запрос почтовым отправлением',
      'Запрос факсимильной связью',
      'Запрос лично или через представителя'
    ],
    tags: [
      'продать бытовую технику',
      'продать холодильник бу',
      'продать стиральную машину'
    ],
    forCards: [CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 11,
    title: 'Займы, микрозаймы, кредиты (сопровождение и помощь в получении)',
    description: 'Сопровождение и помощь в получении кредитов, займов, микрозаймов.',
    options: [
      'Анализ клиента',
      'Помощь в подготовке документов',
      'Консультация по решению проблемных вопросов'
    ],
    tags: [],
    forCards: [CardsIndividual.lite, CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 12,
    title: 'Лизинг автомобиля',
    description: 'Поможем оформить лизинг автомобиля – это долгосрочная аренда с возможностью выкупа авто после уплаты всех платежей.',
    options: [
      'Оформить проще, чем автокредит',
      'Не нужно ставить ТС на учёт, платить транспортный налог и оформлять договор страхования – всё это делает лизингодатель',
      'Юридическая защита на стадии заключения сделки'
    ],
    tags: [
      'машина в лизинг',
      'лизинг авто',
      'купить авто в лизинг'
    ],
    forCards: [CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 13,
    title: 'Лизинг квартиры',
    description: 'Оформляется быстрее и проще ипотеки. Понадобится минимум документов: паспорт, СНИЛС, ИНН. Квартиру приобретает лизинговая компания (лизингодатель), а не банк, как при ипотечном кредитовании. Залог или поручители при такой сделке не нужны.',
    options: [
      'Быстрое оформление',
      'Выгодные условия',
      'Юридическое сопровождение сделки'
    ],
    tags: [
      'квартира в лизинг',
      'лизинг квартиры для физических',
      'квартира в лизинг для физических лиц'
    ],
    forCards: [CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 14,
    title: 'Срочный выкуп автомобилей',
    description: 'Поможем быстро продать автомобиль по достойной цене.',
    options: [
      'Оценка ТС специалистом по фото или при живом осмотре',
      'Приём к выкупу любых авто: целых, битых, проблемных, с запретами',
      'Юридическая защита сделки'
    ],
    tags: [
      'продать авто',
      'выкуп авто',
      'как продать машину'
    ],
    forCards: [CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 15,
    title: 'Срочный выкуп квартиры',
    description: 'Помогаем подготовить все необходимые документы для продажи квартиры. Также предоставим услуги трейд-ин – обмен старой квартиры на новую или зачет стоимости старой квартиры при покупке новой.',
    options: [
      'Оперативный выезд агента для оценки',
      'Максимальное сохранение рыночной стоимости',
      'Юридическое сопровождение сделки'
    ],
    tags: [
      'выкуп квартир',
      'как продать квартиру',
      'срочный выкуп квартир'
    ],
    forCards: [CardsIndividual.optima, CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 16,
    title: 'Лизинг мобильного телефона',
    description: 'Услуга позволяет взять в пользование устройство с установленной ежемесячной абонентской платой.',
    options: [
      'Условия выгоднее, чем при кредите',
      'Возможность обмена телефона на новый через 12 месяцев',
      'Юридическая защита сделки'
    ],
    tags: [
      'телефон в лизинг',
      'айфон в лизинг',
      'лизинг мобильных телефонов'
    ],
    forCards: [CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 17,
    title: 'Совместные покупки',
    description: 'Совместные закупки – принцип организации покупок, при котором набирается группа желающих приобрести товар крупной партией по оптовой цене.',
    options: [
      'Безопасная онлайн-оплата на сайте',
      'Цены ниже, чем в интернет-магазинах',
      'Защита от любого вида мошенничества',
      'Быстрая доставка по всей России'
    ],
    tags: [
      'совместные покупки',
      'база поставщиков',
      'купить товары недорого'
    ],
    forCards: [CardsIndividual.premium],
    price: 'в рамках подписки',
  },
  {
    id: 18,
    title: 'Мастер-класс для начинающих юристов',
    description: 'Если вы планируете стать юристом или уже отучились, но не знаете, куда пойти работать, наши эксперты расскажут: с чего начать обучение, самообразование, что делать юристу без опыта и как стать профессионалом в мире права.',
    options: [
      'Эксперты с большим опытом работы в юриспруденции',
      'Конкретные рекомендации, которые сразу можно применить на практике',
      'Консультации по карьерному развитию'
    ],
    tags: [
      'карьера юриста',
      'как стать юристом',
      'юрист без опыта'
    ],
    forCards: [CardsIndividual.premium],
    price: 'в рамках подписки',
  },
]

export const legalCards = [
  {
    name: CardsLegal.start,
    title: "Стартовый",
    subtitle: "1 год",
    cost: "12 000₽",
  },
  {
    name: CardsLegal.standart,
    title: "Стандарт",
    subtitle: "1 год",
    cost: "60 000₽",
  },
  {
    name: CardsLegal.business,
    title: "Бизнес",
    subtitle: "1 год",
    cost: "120 000₽",
  },
  {
    name: CardsLegal.corp,
    title: "Корпоративный",
    subtitle: "1 год",
    cost: "180 000₽",
  }
]

export const individualCards = [
  {
    name: CardsIndividual.prime,
    title: "Прайм",
    subtitle: "1 месяц",
    cost: "200₽",
  },
  {
    name: CardsIndividual.lite,
    title: "Лайт",
    subtitle: "1 год",
    cost: "1 500₽",
  },
  {
    name: CardsIndividual.optima,
    title: "Оптима",
    subtitle: "1 год",
    cost: "2 500₽",
  },
  {
    name: CardsIndividual.premium,
    title: "Премиум",
    subtitle: "1 год",
    cost: "5 000₽",
  }
]