import {memo, useCallback} from "react";
import styles from "./Peoples.module.css";
import clsx from "clsx";
import GMH from '../../images/poeples/GMH.png'
import BMB from '../../images/poeples/BMB.png'
import KVH from '../../images/poeples/KVH.png'
import {useMediaQuery} from "@mui/material";

const peoples = [
  {
    name: 'гедуев мурат хазретович',
    description: 'основатель компании',
    image: GMH,
    text: `В 2006 году окончил с отличием Ессентукский институт управления бизнеса и права по специальности «юриспруденция», в 2007-м – обучался в Московской международной высшей школе бизнеса «МИРБИС».
Более 14 лет проработал в Кавказском управлении Ростехнадзора. Прошёл путь от специалиста-эксперта до начальника отдела правового обеспечения, организации надзорной и разрешительной деятельности по КБР.
Благодаря большому опыту работы в государственном контролирующем органе знает, как грамотно и быстро оформить сложные разрешительные документы и помогает в этом нашим клиентам. Самые сложные дела берет на личный контроль.
Имеет чин советника государственной гражданской службы РФ 1 класса. С 2010 года является адвокатом Адвокатской палаты КБР.
Несколько раз награжден грамотой Федеральной службы по экологическому, техническому и атомному надзору за безупречную государственную гражданскую службу.`,
  },
  {
    name: 'березгова марьяна борисовна',
    description: 'руководитель юридического отдела',
    image: BMB,
    text: `В 2011 году окончила Государственное общеобразовательное учреждение высшего профессионального образования «Саратовская государственная академия права» по специальности «юриспруденция».
Более 10 лет проработала юристом в разных организациях, в том числе в государственных органах: управлении вневедомственной охраны МВД и в Министерстве финансов КБР.
Имеет классный чин – Советник государственной гражданской службы КБР 2 класса (Минфин КБР 03.10.2019 г.)
Проходила повышение квалификации в Российской академии народного хозяйства и государственной службы при Президенте РФ.`,
  },
  {
    name: 'кяров валерий хаутиевич',
    description: 'юрист',
    image: KVH,
    text: `Опыт работы:
1991-1994 гг. Экономист, ведущий экономист министерства финансов КБР
1994-2000 гг. Начальник сектора ценных бумаг Кабардино-Балкарского банка Сбербанка России
2001-2010 гг. Начальник сектора ценных бумаг Кабардино-Балкарского отделения 8631 Сбербанка России г. Нальчик 
2010-2015 Начальник отдела по обслуживанию на финансовых рынках Кабардино-Балкарского отделения 8631 Сбербанка России г. Нальчик 
2015-2017 Старший аналитик Аналитического центра «Киннгзвинфорд» 
2017-2023 Консультант по юридическим вопросам`,
  }
]


function Peoples() {
  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <div className={styles.container} id="team">
      <p className={clsx('h2', styles.title)}>Команда</p>

      {!isMobile && <div className={styles.rows}>
        {peoples.map((item, index) => (
          <div key={item.name} className={styles.row}>
            {index % 2 ?
              (<>
                <div className={styles.card_left}>
                  <div>
                    <p>{item.text}</p>
                  </div>
                </div>
                <div className={styles.img_right}>
                  <img src={item.image} alt={item.name}/>
                  <div>
                    <p className={clsx('h3', styles.name)}>{item.name}</p>
                    <p className={clsx('text_large', styles.description)}>{item.description}</p>
                  </div>
                </div>
              </>) : (<>
                <div className={styles.img_left}>
                  <img src={item.image} alt={item.name}/>
                  <div>
                    <p className={clsx('h3', styles.name)}>{item.name}</p>
                    <p className={clsx('text_large', styles.description)}>{item.description}</p>
                  </div>
                </div>
                <div className={styles.card_right}>
                  <div>
                    <p>{item.text}</p>
                  </div>
                </div>
              </>)
            }
          </div>
        ))}
      </div>}
      {isMobile && <div className={styles.rows}>
        {peoples.map((item) => (
          <div key={item.name} className={styles.row}>
            <div className={styles.img}>
              <img src={item.image} alt={item.name}/>
              <div>
                <p className={clsx('h3', styles.name)}>{item.name}</p>
                <p className={clsx('text_large', styles.description)}>{item.description}</p>
              </div>
            </div>
            <div className={styles.card}>
              <div>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>}
    </div>
  )
}

export default memo(Peoples);