import {memo, useEffect, useState} from "react";
import styles from "./LatestNews.module.css";
import clsx from "clsx";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {fetchNews} from "../../redux/models/news";
import foto1 from "../../images/fotos/foto1.jpeg";
import foto2 from "../../images/fotos/foto2.jpeg";
import foto3 from "../../images/fotos/foto3.jpeg";
import foto4 from "../../images/fotos/foto4.jpeg";
import foto5 from "../../images/fotos/foto5.jpeg";
import foto6 from "../../images/fotos/foto6.jpeg";
import foto7 from "../../images/fotos/foto7.jpeg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import {EffectCoverflow, Keyboard, Navigation, Pagination} from "swiper";

function LatestNews() {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.news.data);
  const isFetching = useAppSelector((state) => state.news.isFetching);
  const isFetchMore = useAppSelector((state) => state.news.isFetchMore);

  const [pagination, setPagination] = useState<Record<'take' | 'skip', number>>({take: 1, skip: 0});

  const handleFetchNews = () => {
    if (isFetching) return;
    const newPagination = {take: pagination.take, skip: pagination.skip + pagination.take};
    dispatch(fetchNews(newPagination));
    setPagination(newPagination)
  }

  useEffect(() => {
    dispatch(fetchNews(pagination));
  }, []);

  return (
      <div className={styles.latest_news}>
        <h1 className="h2">Последние новости</h1>
        <div className={styles.latest_news_container}>
          <div className={styles.latest_news_container_item}>

            <div className={styles.latest_news_container_item_photo}>
              <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  keyboard={{
                    enabled: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Keyboard, Pagination, Navigation]}
                  className={clsx(styles.swiper, "mySwiper")}
                  style={{width: "100% !important"}}
              >
                <SwiperSlide  className={styles.foto_container}>
                  <img src={foto1} />
                </SwiperSlide>
                {/*<SwiperSlide  className={styles.foto_container}>*/}
                {/*  <img src={foto2}  className={styles.foto2}/>*/}
                {/*</SwiperSlide>*/}
                <SwiperSlide  className={styles.foto_container}>
                  <img src={foto3} />
                </SwiperSlide>
                {/*<SwiperSlide  className={styles.foto_container}>*/}
                {/*  <img src={foto4} />*/}
                {/*</SwiperSlide>*/}
                <SwiperSlide  className={styles.foto_container}>
                  <img src={foto5} />
                </SwiperSlide>
                <SwiperSlide  className={styles.foto_container}>
                  <img src={foto6} />
                </SwiperSlide>
                <SwiperSlide  className={styles.foto_container}>
                  <img src={foto7} />
                </SwiperSlide>

              </Swiper>
            </div>
            <div className={styles.latest_news_container_item_text}>
              <div className={clsx("text", styles.latest_news_container_date)}>
                24.11.22
              </div>
              <h2 className="h5">ЕМФЦ принял активное участие во Всероссийском Дне правовой помощи детям</h2>
              <p className="text_news">Более 35 граждан республики получили бесплатную юридическую консультацию в
                Национальной библиотеке КБР в рамках Всероссийского дня правовой помощи детям. Большая часть обращений
                было связано с вопросами усыновления; определением места жительства и материальным содержанием ребёнка
                после развода родителей, регистрацией несовершеннолетних, получению пособия на опекаемых детей и
                оформлению наследства. Заместитель руководителя «ЕМФЦ» Наталия Геталова вместе с коллегами из Управления
                Министерства юстиции России по КБР, Адвокатской палаты, Службы судебных приставов и Отдела кадровой и
                правовой службы центра СПИД в деталях разъяснили заявителям суть той или иной ситуации. Параллельно
                поделились опытом со студентами 4 курса КБГУ.
                Подводя на круглом столе итоги Дня правовой помощи Председатель Совета Кабардино-Балкарского
                регионального отделения Ассоциации юристов России Эмма Казгериева отметила, что нужно находить время для
                поддержки детей из социально незащищенных слоёв населения, которые порой сталкиваются с проблемами
                правового характера и не знают, как их решить. Для того, чтобы они имели постоянный доступ к такой
                помощи, Ассоциация планирует проводить на регулярной основе бесплатные консультации в районных и
                городских библиотеках Кабардино-Балкарии. Такую модель довольно успешно практикует Единый
                многофункциональный центр правовой помощи и с него нужно брать пример, подчеркнула Казгериева. Так, с
                октября месяца ЕМФЦ безвозмездно проконсультировал более 300 граждан республики. А накануне стартовал
                второй этап акции «Дни бесплатной юридической консультации в муниципалитетах». 24 ноября специалисты
                компании вновь побывают в Прохладненском и Майском районах. Эмма Казгериева также сообщила о вручении в
                декабре премии «Юрист Года КБР» и учреждении премии имени Юрия Калмыкова.</p>
            </div>
          </div>
          {/*{news.map(item => (*/}
          {/*    <div key={item.id} className={styles.latest_news_container_item}>*/}
          {/*      {Boolean(item.media) && (*/}
          {/*          <div className={styles.latest_news_container_item_photo}>*/}
          {/*            <p>фото</p>*/}
          {/*          </div>*/}
          {/*        )*/}
          {/*      }*/}
          {/*      <div className={styles.latest_news_container_item_text}>*/}
          {/*        <div className={clsx("text", styles.latest_news_container_date)}>*/}
          {/*          {dayjs(item.createdAt).format("DD.MM.YY")}*/}
          {/*        </div>*/}
          {/*        <h2 className="h5">{item.title}</h2>*/}
          {/*        <p className="text_news">{item.description}</p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  )*/}
          {/*)}*/}
          {isFetchMore && (<button
              className={clsx(styles.latest_news_btn, "btn_title")}
              onClick={handleFetchNews}
          >
            Загрузить ещё
          </button>)}
        </div>
      </div>
  )
}

export default memo(LatestNews);