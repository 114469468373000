import {memo} from "react";
import styles from "./PrizeBanner.module.css";
import clsx from "clsx";
import presentBoxNew from "../../../../images/presentBoxNew.png";
import presentIcon from "../../../../images/prizeIcon.svg";

function PrizeBanner() {
  return (
    <div className={styles.container}>
      <div id="news" className={styles.content}>
        <img className={styles.icon} src={presentIcon} alt="presentIcon"/>
        <h2 className={clsx("h1", styles.title)}>
          УЧАСТВУЙ В ДЕНЕЖНОМ РОЗЫГРЫШЕ ОТ ЕМФЦ
        </h2>
        <p className={clsx("h1_des", styles.description)}>
          В розыгрыше принимают участие обладатели абонентских карт
        </p>
      </div>
      <div className={styles.wrapper}>
        <img className={styles.image} src={presentBoxNew} alt="presentBoxes"/>
      </div>
    </div>
  )
}

export default memo(PrizeBanner);