import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface CardProps {
  title: string;
  text: string;
  badge: string;
}

export interface MetaProps {
  title: string;
  subTitle: [string, string, string];
  cards: CardProps[];
}

export interface MetaEmailProps {
  title: string;
  isIndividuals?: boolean;
}

export interface ModalState {
  isOpenEmail: boolean;
  isOpen: boolean;
  meta?: MetaProps;
  metaEmail?: MetaEmailProps;
}

const initialState: ModalState = {
  isOpenEmail: false,
  isOpen: false,
  meta: undefined,
  metaEmail: undefined
};

export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    changeModalStatus: (state, action: PayloadAction<Omit<ModalState, 'isOpenEmail'>>) => {
      state.isOpen = action.payload.isOpen;
      state.meta = action.payload.meta;
    },
    changeEmailModalStatus: (state, action: PayloadAction<Omit<ModalState, 'isOpen'>>) => {
      state.isOpenEmail = action.payload.isOpenEmail;
      state.metaEmail = action.payload.metaEmail;
    }
  }
});

export const {changeModalStatus, changeEmailModalStatus} = modal.actions;

export default modal.reducer;
