import styles from "./TextInfo.module.css";

export const TextInfo = () => {
  return (
    <div id="about" className={styles.text_info_container}>
      <h2 className="h2">Что стоит знать о нас?</h2>
      <div className={styles.text_info_center_container}>
        <h4 className="h4">ЕМФЦ (ЕДИНЫЙ МНОГОФУНКЦИОНАЛЬ<br />НЫЙ ЦЕНТР ПРАВОВОЙ ПОМОЩИ)</h4>
        <div className={styles.text_info_right_container}>
          <p className="text_large">
            ЕМФЦ (единый многофункциональный центр правовой поддержки) – место, где и физические лица, и владельцы бизнеса могут решить все юридические, технические и бытовые вопросы: от составления резюме до срочного выкупа квартиры или получения сложных промышленных патентов.
          </p>
        </div>
      </div>
      <div className={styles.text_info_bottom_container}>
        <div>
          <h2 className="h2">1000+</h2>
          <p className="h2_des">ЧЕЛОВЕК ПРОКОНСУЛЬТИРОВАЛИ</p>
        </div>
        <div>
          <h2 className="h2">400+</h2>
          <p className="h2_des">ЗАЯВОК ОТ ГРАЖДАН ОБРАБОТАЛИ</p>
        </div>
        <div>
          <h2 className="h2">215</h2>
          <p className="h2_des">ДЕЛ ЗАВЕРШИЛИ УСПЕШНО</p>
        </div>
      </div>
    </div>
  )
}