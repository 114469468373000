import {memo} from "react";
import styles from "./TrackChanges.module.css";
import papers from "../../images/papers.png";
import clsx from "clsx";
import Footer from "../Footer/Footer";
import Contacts from "../ContactLigth/ContactLight";
import LatestNews from "../LatestNews/LatestNews";

function TrackChanges() {
  return (
    <div className={styles.track_changes}>
      <div className={styles.track_changes_container}>
        <div className={styles.track_changes_container_text}>
          <p className="h1">Следить за изменениями просто</p>
          <h6 className={clsx(styles.track_changes_container_text_small, "h1_des")}>Оставайтесь в курсе
            последних
            изменений в законодательстве вместе с нашей газетой</h6>
        </div>
        <div className={styles.track_changes_container_box}>
          <img src={papers} alt="news"/>
        </div>
      </div>
      <LatestNews/>
      <Contacts/>
      <Footer/>
    </div>
  )
}

export default memo(TrackChanges);