import {memo, useCallback, useState} from "react";
import styles from "./Footer.module.css";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  changeMainStatus,
  changeNewsStatus,
  changePrizesStatus,
  changeServicesStatus,
  changeStandaloneServicesStatus
} from "../../redux/models/main";
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from "clsx";
import {RedirectButtons} from "../RedirectButtons/RedirectButtons";

const policy = require("../../files/policy.pdf");
const file3 = require("../../files/ДОГОВОР ОФЕРТЫ.pdf");

function Footer() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width:480px)');
  const isNewsPage = useAppSelector((state) => state.main.isNewsPage);
  const isMainPage = useAppSelector((state) => state.main.isMainPage);
  const isPrizePage = useAppSelector((state) => state.main.isPrizePage);
  const isStandaloneServicesPage = useAppSelector((state) => state.main.isStandaloneServicesPage);

  const disableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(false))
    },
    [dispatch],
  );

  const enableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(false))
    },
    [dispatch],
  );

  const enablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(true));
      dispatch(changeNewsStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const enableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeNewsStatus(false));
    },
    [dispatch],
  );

  const closePages = useCallback(
    () => {
      disableNewsStatus();
      disablePrizeStatus();
      disableStandaloneServicesStatus();
    },
    [dispatch],
  );

  const handleChangePageStatus = useCallback(
    () => {
      closePages();
      dispatch(changeMainStatus(true));
    },
    [closePages, dispatch],
  );

  const cleanHash = () => {
    location.hash = ''
  }

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footer_container}>
          <div className={styles.footer_container_first} onClick={cleanHash}>
            <button onClick={closePages} className="text"><a href="#team">Команда</a></button>

            {isMainPage && (
              <button onClick={closePages} className="text"><a href="#about">О нас</a></button>
            )}
            {!isMainPage && (
              <button onClick={closePages} className="text"><a href="#service_rates">Тарифы</a></button>
            )}

            <button onClick={enableNewsStatus} className="text"><a href="#news">Новости</a></button>

            <button onClick={closePages} className="text"><a href="#contacts">Контакты</a></button>
            {!isPrizePage && (
              <button onClick={enablePrizeStatus} className="text"><a href="#prize">Розыгрыши</a></button>
            )}
            {!isStandaloneServicesPage && (
              <button onClick={enableStandaloneServicesStatus} className="text"><a>Платные услуги</a></button>
            )}

          </div>
          <div className={styles.footer_container_second}>
            <a
              className="text"
              href={policy}
              target="_blank"
            >
              Политика конфиденциальности
            </a>
            <a
              className="text"
              href={file3}
              target="_blank"
            >
              Договор оферты
            </a>
          </div>
        </div>
      </div>

      {isMobile && (
        <>
          {!isNewsPage && !isPrizePage && !isStandaloneServicesPage && <RedirectButtons isFullWidth/>}
          <div className={styles.back_btn_container}>
            <a className={styles.btn_link} href="#main_input">
              <button
                onClick={closePages}
                className={clsx("text_large", styles.back_btn)}
              >
                Наверх
              </button>
            </a>
          </div>
        </>
      )}
    </>
  )
}

export default memo(Footer);