import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

export interface NewsData {
  id: number;
  title: string;
  description: string;
  media: string | null;
  mediaType: "image" | "video" | null;
  createdAt: string;
}

export interface NewsState {
  data: NewsData[];
  isFetching: boolean;
  isFetchMore: boolean;
}

export const fetchNews = createAsyncThunk("news/get", async ({take, skip}: {take: number, skip: number}) => {
  const response = await axios.get(`http://localhost:4000/route/news`, {
    params: {
      take,
      skip,
    }
  });

  return response?.data;
})

const initialState: NewsState = {
  data: [],
  isFetching: false,
  isFetchMore: true,
};

export const news = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.isFetching = true;
    })
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.data = [...state.data, ...action.payload.data.result];
      state.isFetchMore = action.payload.data.isFetchMore;
      state.isFetching = false;
    })
    builder.addCase(fetchNews.rejected, (state) => {
      state.isFetching = false;
      state.isFetchMore = false;
    })
  }
});

export const {} = news.actions;

export default news.reducer;
