import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CardsIndividual, CardsLegal} from '../../utils';


export interface MainState {
  isMainPage: boolean;
  isNewsPage: boolean;
  isPrizePage: boolean;
  isStandaloneServicesPage: boolean;
  isIndividualState: boolean;
  isServicesPage: boolean;
  activeCard: CardsIndividual | CardsLegal | null;
}

const initialState: MainState = {
  isMainPage: true,
  isNewsPage: false,
  isPrizePage: false,
  isStandaloneServicesPage: false,
  isIndividualState: false,
  isServicesPage: false,
  activeCard: null,
};

export const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    changeMainStatus: (state, action: PayloadAction<boolean>) => {
      state.isMainPage = action.payload;
    },
    changeNewsStatus: (state, action: PayloadAction<boolean>) => {
      state.isNewsPage = action.payload;
    },
    changePrizesStatus: (state, action: PayloadAction<boolean>) => {
      state.isPrizePage = action.payload;
    },
    changeStandaloneServicesStatus: (state, action: PayloadAction<boolean>) => {
      state.isStandaloneServicesPage = action.payload;
    },
    changeIndividualStatus: (state, action: PayloadAction<boolean>) => {
      state.isIndividualState = action.payload;
    },
    changeServicesStatus: (state, action: PayloadAction<boolean>) => {
      state.isServicesPage = action.payload;
    },
    changeActiveCard: (state, action: PayloadAction<CardsIndividual | CardsLegal>) => {
      state.activeCard = action.payload;
    }
  },
});

export const {
  changeMainStatus,
  changeNewsStatus,
  changePrizesStatus,
  changeStandaloneServicesStatus,
  changeIndividualStatus,
  changeServicesStatus,
  changeActiveCard,
} = main.actions;

export default main.reducer;
