import React, {FC, Fragment, memo, useCallback} from "react";
import styles from "./TopPlaces.module.css";
import clsx from "clsx";
import {useMediaQuery} from "@mui/material";
import primeIcon from "../../../../images/cards/prime.png";
import liteIcon from "../../../../images/cards/lite.png";
import optimaIcon from "../../../../images/cards/optima.png";
import premiumIcon from "../../../../images/cards/premium.png";
import {changeEmailModalStatus} from "../../../../redux/models/modal";
import {useAppDispatch} from "../../../../redux/hooks";


const cards = [{
  name: "Прайм",
  icon: primeIcon,
  places: [
    {
      title: '1 место',
      description: '1 000 рублей'
    },
    {
      title: '2 место',
      description: '1 000 рублей'
    },
    {
      title: '3 место',
      description: '1 000 рублей'
    },
    {
      title: '4 место',
      description: '1 000 рублей'
    },
    {
      title: '5 место',
      description: '1 000 рублей'
    },
    {
      title: '6 место',
      description: '1 000 рублей'
    },
    {
      title: '7 место',
      description: '1 000 рублей'
    },
    {
      title: '8 место',
      description: '1 000 рублей'
    },
    {
      title: '9 место',
      description: '1 000 рублей'
    },
    {
      title: '10 место',
      description: '1 000 рублей'
    },
    {
      title: '11 место',
      description: '10 000 рублей'
    },
  ]
}, {
  name: "Лайт",
  icon: liteIcon,
  places: [
    {
      title: "1 место",
      description: "Айфон 15"
    },
    {
      title: "2 место",
      description: "3 000 рублей"
    },
    {
      title: "3 место",
      description: "3 000 рублей"
    },
    {
      title: "4 место",
      description: "3 000 рублей"
    },
    {
      title: "5 место",
      description: "3 000 рублей"
    },
    {
      title: "6 место",
      description: "3 000 рублей"
    },
    {
      title: "7 место",
      description: "3 000 рублей"
    },
    {
      title: "8 место",
      description: "3 000 рублей"
    },
    {
      title: "9 место",
      description: "3 000 рублей"
    },
    {
      title: "10 место",
      description: "3 000 рублей"
    },
    {
      title: "11 место",
      description: "3 000 рублей"
    },
    {
      title: "12 место",
      description: "3 000 рублей"
    },
    {
      title: "13 место",
      description: "3 000 рублей"
    },
    {
      title: "14 место",
      description: "3 000 рублей"
    },
    {
      title: "15 место",
      description: "3 000 рублей"
    },
    {
      title: "16 место",
      description: "3 000 рублей"
    },
    {
      title: "17 место",
      description: "3 000 рублей"
    },
    {
      title: "18 место",
      description: "3 000 рублей"
    },
    {
      title: "19 место",
      description: "3 000 рублей"
    },
    {
      title: "20 место",
      description: "3 000 рублей"
    },
    {
      title: "21 место",
      description: "3 000 рублей"
    },
    {
      title: "22 место",
      description: "5 000 рублей"
    },
    {
      title: "23 место",
      description: "5 000 рублей"
    },
    {
      title: "24 место",
      description: "5 000 рублей"
    },
    {
      title: "25 место",
      description: "5 000 рублей"
    },
    {
      title: "26 место",
      description: "5 000 рублей"
    },
    {
      title: "27 место",
      description: "5 000 рублей"
    },
    {
      title: "28 место",
      description: "5 000 рублей"
    },
    {
      title: "29 место",
      description: "5 000 рублей"
    },
    {
      title: "30 место",
      description: "5 000 рублей"
    },
    {
      title: "31 место",
      description: "5 000 рублей"
    },
    {
      title: "32 место",
      description: "Айфон 15"
    },
    {
      title: "33 место",
      description: "10 000 рублей"
    },
    {
      title: "34 место",
      description: "10 000 рублей"
    },
    {
      title: "35 место",
      description: "10 000 рублей"
    },
    {
      title: "36 место",
      description: "10 000 рублей"
    },
    {
      title: "37 место",
      description: "10 000 рублей"
    },
    {
      title: "38 место",
      description: "15 000 рублей"
    },
    {
      title: "39 место",
      description: "15 000 рублей"
    },
    {
      title: "40 место",
      description: "15 000 рублей"
    },
    {
      title: "41 место",
      description: "15 000 рублей"
    },
    {
      title: "42 место",
      description: "15 000 рублей"
    }
  ]
}, {
  name: "Оптима",
  icon: optimaIcon,
  places: [
    {
      title: "1 место",
      description: "100 000 рублей"
    },
    {
      title: "2 место",
      description: "5 000 рублей"
    },
    {
      title: "3 место",
      description: "5 000 рублей"
    },
    {
      title: "4 место",
      description: "5 000 рублей"
    },
    {
      title: "5 место",
      description: "5 000 рублей"
    },
    {
      title: "6 место",
      description: "5 000 рублей"
    },
    {
      title: "7 место",
      description: "5 000 рублей"
    },
    {
      title: "8 место",
      description: "5 000 рублей"
    },
    {
      title: "9 место",
      description: "5 000 рублей"
    },
    {
      title: "10 место",
      description: "5 000 рублей"
    },
    {
      title: "11 место",
      description: "5 000 рублей"
    },
    {
      title: "12 место",
      description: "5 000 рублей"
    },
    {
      title: "13 место",
      description: "5 000 рублей"
    },
    {
      title: "14 место",
      description: "5 000 рублей"
    },
    {
      title: "15 место",
      description: "5 000 рублей"
    },
    {
      title: "16 место",
      description: "5 000 рублей"
    },
    {
      title: "17 место",
      description: "5 000 рублей"
    },
    {
      title: "18 место",
      description: "5 000 рублей"
    },
    {
      title: "19 место",
      description: "5 000 рублей"
    },
    {
      title: "20 место",
      description: "5 000 рублей"
    },
    {
      title: "21 место",
      description: "5 000 рублей"
    },
    {
      title: "22 место",
      description: "10 000 рублей"
    },
    {
      title: "23 место",
      description: "10 000 рублей"
    },
    {
      title: "24 место",
      description: "10 000 рублей"
    },
    {
      title: "25 место",
      description: "10 000 рублей"
    },
    {
      title: "26 место",
      description: "10 000 рублей"
    },
    {
      title: "27 место",
      description: "10 000 рублей"
    },
    {
      title: "28 место",
      description: "10 000 рублей"
    },
    {
      title: "29 место",
      description: "10 000 рублей"
    },
    {
      title: "30 место",
      description: "10 000 рублей"
    },
    {
      title: "31 место",
      description: "10 000 рублей"
    },
    {
      title: "32 место",
      description: "20 000 рублей"
    },
    {
      title: "33 место",
      description: "30 000 рублей"
    },
    {
      title: "34 место",
      description: "100 000 рублей"
    }
  ]
}, {
  name: "Премиум",
  icon: premiumIcon,
  places: [
    {
      title: "1 место",
      description: "1 000 000 рублей"
    },
    {
      title: "2 место",
      description: "10 000 рублей"
    },
    {
      title: "3 место",
      description: "10 000 рублей"
    },
    {
      title: "4 место",
      description: "10 000 рублей"
    },
    {
      title: "5 место",
      description: "10 000 рублей"
    },
    {
      title: "6 место",
      description: "10 000 рублей"
    },
    {
      title: "7 место",
      description: "10 000 рублей"
    },
    {
      title: "8 место",
      description: "10 000 рублей"
    },
    {
      title: "9 место",
      description: "10 000 рублей"
    },
    {
      title: "10 место",
      description: "10 000 рублей"
    },
    {
      title: "11 место",
      description: "10 000 рублей"
    },
    {
      title: "12 место",
      description: "10 000 рублей"
    },
    {
      title: "13 место",
      description: "10 000 рублей"
    },
    {
      title: "14 место",
      description: "10 000 рублей"
    },
    {
      title: "15 место",
      description: "10 000 рублей"
    },
    {
      title: "16 место",
      description: "10 000 рублей"
    },
    {
      title: "17 место",
      description: "10 000 рублей"
    },
    {
      title: "18 место",
      description: "10 000 рублей"
    },
    {
      title: "19 место",
      description: "10 000 рублей"
    },
    {
      title: "20 место",
      description: "10 000 рублей"
    },
    {
      title: "21 место",
      description: "10 000 рублей"
    },
    {
      title: "22 место",
      description: "20 000 рублей"
    },
    {
      title: "23 место",
      description: "20 000 рублей"
    },
    {
      title: "24 место",
      description: "20 000 рублей"
    },
    {
      title: "25 место",
      description: "20 000 рублей"
    },
    {
      title: "26 место",
      description: "20 000 рублей"
    },
    {
      title: "27 место",
      description: "20 000 рублей"
    },
    {
      title: "28 место",
      description: "20 000 рублей"
    },
    {
      title: "29 место",
      description: "20 000 рублей"
    },
    {
      title: "30 место",
      description: "20 000 рублей"
    },
    {
      title: "31 место",
      description: "20 000 рублей"
    },
    {
      title: "32 место",
      description: "50 000 рублей"
    },
    {
      title: "33 место",
      description: "50 000 рублей"
    },
    {
      title: "34 место",
      description: "30 000 рублей"
    },
    {
      title: "35 место",
      description: "30 000 рублей"
    },
    {
      title: "36 место",
      description: "30 000 рублей"
    },
    {
      title: "37 место",
      description: "15 000 рублей"
    },
    {
      title: "38 место",
      description: "15 000 рублей"
    },
    {
      title: "39 место",
      description: "15 000 рублей"
    },
    {
      title: "40 место",
      description: "15 000 рублей"
    },
    {
      title: "41 место",
      description: "15 000 рублей"
    },
    {
      title: "42 место",
      description: "15 000 рублей"
    },
    {
      title: "43 место",
      description: "15 000 рублей"
    },
    {
      title: "44 место",
      description: "15 000 рублей"
    },
    {
      title: "45 место",
      description: "15 000 рублей"
    },
    {
      title: "46 место",
      description: "15 000 рублей"
    },
    {
      title: "47 место",
      description: "100 000 рублей"
    },
    {
      title: "48 место",
      description: "25 000 рублей"
    },
    {
      title: "49 место",
      description: "25 000 рублей"
    },
    {
      title: "50 место",
      description: "25 000 рублей"
    }
  ]
}]

const TopPlaces: FC = () => {
  const [active, setActive] = React.useState(cards[0]);
  const isMobile = useMediaQuery('(max-width:480px)');
  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    (title: string) => {
      dispatch(changeEmailModalStatus({
        isOpenEmail: true,
        metaEmail: {title, isIndividuals: true},
      }))
    },
    [dispatch],
  );


  return (
    <div className={styles.container}>
      <p className={clsx(styles.title, "h2")}>Какие будут призы</p>
      {!isMobile && (
        <>
          <div className={styles.tabs}>
            {cards.map((card) => (
              <div
                className={clsx(styles.tab, "h3", active.name === card.name && styles.selected)}
                onClick={() => {
                  setActive(card)
                }}
              >
                {card.name}
              </div>
            ))}
          </div>
          <div className={styles.tab_container}>
            <img className={styles.card_icon} src={active.icon} alt="card-icon"/>
            <div className={styles.tab_container_info}>
              <p className={clsx('h4')}>Оформите карту уже сейчас</p>
              <p className={clsx(styles.text)}>Для участия в розыгрыше необходимо оформить карту</p>
              <p className={clsx(styles.button, "btn_title")} onClick={handleOpenModal.bind(null, active.name)}>Оформить
                карту</p>
            </div>
          </div>
        </>
      )}
      {
        isMobile && <div className={styles.accordeon_container}>
          {cards.map((card, index) =>
            <Fragment key={card.name}>
              <div
                onClick={() => {
                  setActive(card)
                }}
                className={clsx(styles.accordeon_tab, active.name === card.name && styles.accordeon_selected)}
              >
                <div className={'h3'}>{card.name}</div>
                <div
                  className={clsx(styles.accordeon_plus, active.name === card.name && styles.accordeon_plus_active)}
                >
                  +
                </div>
              </div>
              <div className={clsx(styles.accordeon_item, active.name === card.name && styles.accordeon_item_active)}>
                <div className={styles.accordeon_item_info}>
                  <p className={clsx('h4')}>Оформите карту уже сейчас</p>
                  <p className={clsx(styles.text)}>Для участия в розыгрыше необходимо оформить карту</p>
                  <p className={clsx(styles.button, "btn_title")} onClick={handleOpenModal.bind(null, card.name)}>Оформить
                    карту</p>
                </div>
                <img className={styles.card_icon} src={card.icon} alt="card-icon"/>
              </div>
              {index + 1 !== cards.length && <div className={styles.line}></div>}
            </Fragment>
          )}
        </div>}
      <div className={styles.cards}>
        {active.places.map((item) => (
          <div key={item.title} className={styles.card}>
            <p className={clsx('h4', styles.card_description)} style={{textAlign: 'center'}}>{item.description}</p>
            <p className={clsx('text_news')}>{item.title}</p>
          </div>))}
      </div>
    </div>
  )
}

export default memo(TopPlaces);