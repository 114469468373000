import {memo, useMemo, useState} from "react";
import styles from "./ContactLight.module.css";
import clsx from "clsx";
import logo from "../../images/BlurLogo.png";
import video from "../../images/video.svg";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import { send } from "../../email.send";
import { FormControlLabel, Radio, RadioGroup, Stack, Switch, Typography } from "@mui/material";

interface IFormInput extends Record<string, unknown> {
  name: string;
  phone: string;
  isIndividuals?: boolean;
  type: string;
  registered?: boolean;
}

const policy = require("../../files/policy.pdf");
const person = require("../../files/person.pdf");

function ContactLight() {
  const { register, control, handleSubmit, formState, setValue, watch } = useForm<IFormInput>();
  const errors = useMemo(
      () => formState.errors,
      [formState]
  );  

  const [isLoading, setIsLoading] = useState(false);

  const afterSendAction = () => {
    setValue('name', '');
    setValue('phone', '');
    setValue('type', '');
    setIsLoading(false);
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setIsLoading(true);
        
    send({ ...data as any, isIndividuals: (data as any)?.isIndividuals === 'true' })
      .then(afterSendAction)
      .catch(afterSendAction);
  };


  return (
      <div id="contacts" className={styles.contacts}>
        <div className={styles.contacts_first_flex}>
          <div className={styles.contacts_first_flex_box1}>
            <h1 className="h2">Оставьте заявку, чтобы мы могли связаться с Вами</h1>
            <p className="text">Для начала работы оставьте свои контактные данные. Наш специалист свяжется с вами в ближайшее
              время</p>
          </div>
          <form className={styles.contacts_first_flex_inputs} onSubmit={handleSubmit(onSubmit)}>
            
          <Controller
            name="isIndividuals"
            control={control}
            defaultValue={false}
            render={({field}) => (
              <div
                className={clsx(styles.contacts_first_flex_inputs_info, "text", styles.center, styles.custom)}>
                 <RadioGroup
                  row
                  {...field}
                >
                  <FormControlLabel value={false} control={<Radio />} label="Юр. лицо" />
                  <FormControlLabel value={true} control={<Radio />} label="Физ. лицо" />
                </RadioGroup>
                </ div>
              )}
            />
            <Controller
              name="registered"
              control={control}
              defaultValue={false}
              render={({field}) => (
                <FormControlLabel
                {...field}
                  labelPlacement="start"
                  className={clsx(styles.contacts_first_flex_inputs_info, "text", styles.center, styles.custom)}
                  control={<Switch />}
                  label="Являюсь абонентом ЕМФЦ"
                />
              )}
            />
          <textarea
                {...register("type", {required: true })}
                className={clsx("text", styles.contacts_first_flex_inputs_textarea, errors.name && styles.error)}
                placeholder="Ваш вопрос"
                rows={3}
            />
            <input
                {...register("name", {required: true })}
                className={clsx("text", styles.contacts_first_flex_inputs_info, errors.name && styles.error)}
                type="text"
                placeholder="Имя"
            />
            <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({field}) => (
                    <InputMask
                        {...field}
                        mask="+7 (999) 999-99-99"
                        maskPlaceholder="="
                        className={clsx(styles.contacts_first_flex_inputs_info, "text", errors.phone && styles.error)}
                        type="tel"
                        placeholder="Телефон"
                    />
                )}
            />
            <button
                className={clsx(styles.contacts_first_flex_inputs_danger, "btn_title", isLoading && 'btn-disabled')}
                type="submit"
                disabled={isLoading}
            >
              Отправить
            </button>
          </form>
          <p className="text_xsmall">Нажимая кнопку «Отправить», Вы соглашаетесь с <a target="_blank" href={policy}>политикой
              конфиденциальности</a> и даете согласие на <a target="_blank" href={person}>обработку персональных данных</a></p>
        </div>
        <div className={styles.contacts_second_flex}>
          <img src={logo} className={styles.logo_mfc} alt="logo"/>
          <p className="h5">Свяжитесь с нами:</p>
          <div className={clsx(styles.flex_info, "text")}>
            {/* <div>
              <a className={styles.video_icon} href="https://t.me/Duq_07" target="__blank">
                <div>
                  <svg fill="none" width="800px" height="800px" viewBox="-4 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 13.219l6.844-3.938c0.906-0.531 1.656-0.156 1.656 0.938v11.625c0 1.063-0.75 1.5-1.656 0.969l-6.844-3.969v2.938c0 1.094-0.875 1.969-1.969 1.969h-11.625c-1.063 0-1.906-0.875-1.906-1.969v-11.594c0-1.094 0.844-1.938 1.906-1.938h11.625c1.094 0 1.969 0.844 1.969 1.938v3.031z"></path>
                  </svg>            
                </div>
                Видеочат
              </a>
            </div> */}
            <div>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30" cy="30" r="30"/>
                <path
                    d="M17.8659 27.2622C21.0081 34.1099 26.5992 39.5542 33.5504 42.5056L34.6647 43.0024C37.214 44.1388 40.2108 43.2754 41.7657 40.9564L43.2219 38.7848C43.6953 38.0787 43.5512 37.1277 42.8898 36.5939L37.9515 32.6076C37.2262 32.0221 36.1596 32.1585 35.6047 32.9077L34.077 34.9703C30.157 33.0355 26.9744 29.851 25.0407 25.9287L27.1021 24.4001C27.8509 23.8449 27.9872 22.7777 27.402 22.0519L23.4179 17.1106C22.8845 16.4489 21.9343 16.3046 21.2287 16.778L19.0433 18.2442C16.7112 19.8089 15.8531 22.8322 17.0151 25.3898L17.8646 27.2595L17.8659 27.2622Z"
                />
              </svg>
              <a className={clsx("text", styles.hover_tel)} href="tel:+78663040000">+ 7 (8663) 04-00-00</a>
            </div>
            <div>
              <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                <circle cx="30" cy="30" r="30"/>
                <path
                    d="M22.8035 19.1657C27.5921 18.7494 32.4079 18.7494 37.1965 19.1657L39.5036 19.3663C41.0395 19.4998 42.3549 20.4159 43.0365 21.7263C43.1237 21.8939 43.0583 22.0969 42.8976 22.1963L33.3254 28.122C31.2724 29.3929 28.6838 29.4195 26.6051 28.1912L16.9689 22.4971C16.8132 22.4051 16.7418 22.2157 16.8119 22.0491C17.4355 20.5679 18.8373 19.5105 20.4964 19.3663L22.8035 19.1657Z"
                />
                <path
                    d="M16.8041 25.0613C16.5658 24.9206 16.2618 25.0721 16.2341 25.3474C15.8464 29.1904 15.9403 33.0698 16.5155 36.8952C16.8188 38.9119 18.4647 40.458 20.4964 40.6346L22.8035 40.8351C27.5921 41.2514 32.4079 41.2514 37.1965 40.8351L39.5036 40.6346C41.5353 40.458 43.1812 38.9119 43.4845 36.8952C44.0765 32.9584 44.1586 28.9643 43.7308 25.0115C43.7008 24.7342 43.3916 24.5855 43.1545 24.7323L34.5315 30.0703C31.754 31.7898 28.2517 31.8259 25.4394 30.164L16.8041 25.0613Z"
                />
              </svg>
              <a className="text" href="mailto:emfc-kbr@mail.ru">emfc-kbr@mail.ru</a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default memo(ContactLight);