import React, {memo, useEffect, useState} from "react";
import styles from "./MainInput.module.css";
import clsx from "clsx";
import Services from "../Services/Services";
import {CardsIndividual, CardsLegal} from '../../utils';
import {ReactComponent as ArraySvg} from '../../images/arrow.svg';
import {useMediaQuery} from "@mui/material";
import Cards from "../Cards/Cards";
import Typography from "@mui/material/Typography";
import {changeStandaloneServicesStatus} from "../../redux/models/main";
import {useAppDispatch} from "../../redux/hooks";

interface IFormInput extends Record<string, unknown> {
  question: string;
  name: string;
  phone: string;
}

const defaultCardsIndividual = [
  CardsIndividual.premium,
  CardsIndividual.optima,
  CardsIndividual.lite,
  CardsIndividual.prime,
];
const defaultCardsIP = [
  CardsLegal.corp,
  CardsLegal.business,
  CardsLegal.standart,
  CardsLegal.start,
];


const MainInput = ({isIndividual = false}: { isIndividual: boolean }) => {
  const [cards, setCards] = useState<(CardsIndividual | CardsLegal)[]>([]);
  const [renderCards, setRenderCards] = useState<(CardsIndividual | CardsLegal)[]>([]);
  const [activeCard, setActiveCard] = useState<CardsIndividual | CardsLegal>();
  const isMobile = useMediaQuery('(max-width:480px)');
  const [firstPosition, setFirstPosition] = useState(true);
  const dispatch = useAppDispatch();

  const title = isIndividual
    ? 'Единая многофункциональная абонентская карта'
    : 'Единая многофункциональная бизнес карта';

  useEffect(() => {
    const activeCards = isIndividual
      ? defaultCardsIndividual
      : defaultCardsIP;
    setCards(activeCards);

    setRenderCards(activeCards)
    setActiveCard(activeCards[0])
  }, [isIndividual]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstPosition(false)
    }, 100)

    return () => {
      clearTimeout(timer);
    }
  }, []);

  const handleChangeActiveCard = (cardName: CardsIndividual | CardsLegal) => () => {
    const offset = cards.indexOf(cardName);
    const cardsOrder = [...cards.slice(offset), ...cards.slice(0, offset)];

    setRenderCards(cardsOrder);
    setActiveCard(cardName);
  }

  const handleOpenStandaloneServices = () => {
    location.hash = ''
    dispatch(changeStandaloneServicesStatus(true));
    setTimeout(() => {
      location.hash = '#standalone-0'
    }, 100)
  }

  return (
    <>
      <div className={styles.tariff_title}>
        {isIndividual
          ? 'Подписка на годовое абонентское обслуживание 24/7 для граждан'
          : 'Подписка на годовое абонентское обслуживание 24/7 для бизнеса'}
      </div>
      <Cards isIndividual={isIndividual}/>
      {isIndividual && <div className={styles.discount_card}>
        <Typography className={clsx(styles.accordion_title, styles.accordion_title)}>
          Всем абонентам ЕМФЦ скидку до 50% на <a onClick={handleOpenStandaloneServices}><span
          className={styles.price_btn}>платные услуги</span></a>
        </Typography>
      </div>}
      <Services isIndividuals={isIndividual} filterCard={activeCard}/>
      {/* <div className={styles.main_poster_container} id={isIndividual ? 'individuals' : 'legals'}>
        <div className={styles.main_poster_container_desc}>
          <p className={clsx("h2_secondary")}>
            {title}
          </p>
          <div>
            <p className={clsx("h1_des")}>
              Выбери свои услуги
            </p>
            {!isMobile && <div className={styles.main_poster_arrow}>
              <ArraySvg width={10} height={10} />
            </div>}
          </div>
        </div>
        <div
          className={clsx(
            styles.main_poster_container_cards,
          )}
        >
          {renderCards.map((card) => (
            <div
              key={card}
              className={
                clsx(
                  styles[card],
                  styles.main_poster_container_card,
                  firstPosition && styles.firstPosition,
                )}
              onClick={handleChangeActiveCard(card)}
            />
          ))}
        </div>
      </div> */}
    </>
  );
}

export default memo(MainInput);

