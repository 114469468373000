import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import CardForHelp from "./components/CardForHelp/CardForHelp";
import {useAppSelector} from "./redux/hooks";
import TrackChanges from "./components/TrackChanges/TrackChanges";
import MainInput from "./components/MainInput/MainInput";
import ServiceRates from "./components/ServiceRates/ServiceRates";
import TripleRequest from "./components/TripleRequest/TripleRequest";
import Footer from "./components/Footer/Footer";
import Modals from "./components/Modals/Modals";
import ContactLight from "./components/ContactLigth/ContactLight";
import Services from "./components/Services/Services";
import {RedirectButtons} from './components/RedirectButtons/RedirectButtons';
import {useMediaQuery} from '@mui/material';
import {MainInfo} from './components/MainInfo/MainInfo';
import {TextInfo} from './components/TextInfo/TextInfo';
import EmailModal from './components/Modals/EmailModal';
import PrizePage from "./components/PrizePage/PrizePage";
import Peoples from "./components/Peoples/Peoples";
import MainBanner from "./components/MainBanner/MainBanner";
import StandaloneServices from "./components/StandaloneServices/StandaloneServices";

function App() {
  const isNewsPage = useAppSelector((state) => state.main.isNewsPage);
  const isPrizePage = useAppSelector((state) => state.main.isPrizePage);
  const isStandaloneServicesPage = useAppSelector((state) => state.main.isStandaloneServicesPage);

  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <div className="App">
      <Header/>
      {isNewsPage && <TrackChanges/>}
      {isPrizePage && <PrizePage/>}
      {isStandaloneServicesPage && <StandaloneServices/>}
      {!isNewsPage && !isPrizePage && !isStandaloneServicesPage &&
        <>
          <MainBanner/>
          <MainInput isIndividual/>
          <MainInput isIndividual={false}/>
          {/* <MainInfo /> */}
          {/* <ServiceRates/> */}
          <Peoples/>
          <TextInfo/>
          <TripleRequest/>
          <ContactLight/>
          {!isMobile && <RedirectButtons/>}
          <Footer/>
        </>
      }
      <Modals/>
      <EmailModal/>
    </div>
  );
}

export default App;
