import React, {memo} from "react";
import styles from "./PrizePage.module.css";
import Footer from "../Footer/Footer";
import PrizeCard from "../PrizeCard/PrizeCard";
import TopPlaces from "./components/TopPlaces/TopPlaces";
import Participate from "./components/Participate/Participate";
import PrizeBanner from "./components/PrizeBanner/PrizeBanner";

function PrizePage() {
  return (
    <div className={styles.track_changes}>
      <PrizeBanner/>
      <TopPlaces/>
      <Participate/>
      <PrizeCard/>
      <Footer/>
    </div>
  )
}

export default memo(PrizePage);