import {memo, useMemo, useState} from "react";
import styles from "./ContactDark.module.css";
import clsx from "clsx";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import { send } from "../../email.send";

interface IFormInput extends Record<string, unknown> {
  name: string;
  phone: string;
  isIndividuals?: boolean;
}

const policy = require("../../files/policy.pdf");
const file3 = require("../../files/ДОГОВОР ОФЕРТЫ.pdf");

function ContactDark() {
  const {register, control, handleSubmit, formState, setValue} = useForm<IFormInput>();

  const errors = useMemo(
      () => formState.errors,
      [formState]
  );

  const [isLoading, setIsLoading] = useState(false);

  const afterSendAction = () => {
    setValue('name', '');
    setValue('phone', '');
    setIsLoading(false);
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setIsLoading(true);

    send(data as any)
    .then(afterSendAction)
    .catch(afterSendAction);
  };

  return (
      <div>
        <div className={styles.contacts2_first_flex}>
          <div className={styles.contacts2_first_flex_box1}>
            <h1 className="h2">Оставьте заявку, чтобы подписаться на тариф</h1>
            <p className="text">Оставьте свои контактные данные, и мы свяжемся с вами в ближайшее время</p>
          </div>
          <form className={styles.contacts2_first_flex_inputs} onSubmit={handleSubmit(onSubmit)}>
            <input
                {...register("name", {required: true })}
                className={clsx("text", styles.contacts2_first_flex_inputs_info)}
                type="text"
                placeholder="Имя"
            />
            <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({field}) => (
                    <InputMask
                        {...field}
                        mask="+7 (999) 999-99-99"
                        maskPlaceholder="="
                        className={clsx(styles.contacts2_first_flex_inputs_info, "text", errors.phone && styles.error)}
                        type="tel"
                        placeholder="Телефон"
                    />
                )}
            />
            <button
                className={clsx("btn_title", styles.contacts2_first_flex_inputs_danger, isLoading && 'btn-disabled')}
                type="submit"
                disabled={isLoading}
            >
              Отправить
            </button>
          </form>
          <p className="text_xsmall">Нажимая кнопку «Отправить», Вы соглашаетесь с <a href={policy} target="_blank"><u>политикой
            конфиденциальности</u></a> и
            подтверждаете, что ознакомились с <a href={file3} target="_blank"><u>договором оферты</u></a></p>
        </div>
      </div>
  )
}

export default memo(ContactDark);