import clsx from "clsx";
import {ReactComponent as ArraySvg} from '../../images/arrow.svg';
import { useMediaQuery } from "@mui/material";
import styles from './RedirectButtons.module.css';

interface RedirectButtonsProps {
  isFullWidth?: boolean;
}

export const RedirectButtons = ({ isFullWidth }: RedirectButtonsProps) => {
  const isMobile = useMediaQuery('(max-width:480px)');

  
  return (
    <a className={clsx(styles.redirect_btns_container, isFullWidth && styles.redirect_btns_container_full_width)}>
      <a href="#individuals">
        <button
          className={clsx("text_large", styles.redirect_btn)}
        >
          Для граждан
          <div>
            <ArraySvg width={isMobile ? 10 : 20} height={isMobile ? 10 : 20} />
          </div>
        </button>
      </a>
      <a href="#legals">
        <button
          className={clsx("text_large", styles.redirect_btn)}
        >
          Для организаций и ИП
          <div>
            <ArraySvg width={isMobile ? 10 : 20} height={isMobile ? 10 : 20} />
          </div>
        </button>
      </a>
    </a>
  )
}