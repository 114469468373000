import React, {forwardRef, useCallback, useState} from 'react';
import {Box, Dialog, Slide} from "@mui/material";
import styles from "./HeaderModal.module.css";
import {TransitionProps} from "@mui/material/transitions";
import clsx from "clsx";
import logo_white from "../../images/logo_white.png";
import tel from "../../images/tel.svg";
import close from '../../images/close_logo.png';
import {
  changeMainStatus,
  changeNewsStatus,
  changePrizesStatus,
  changeServicesStatus,
  changeStandaloneServicesStatus
} from "../../redux/models/main";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import mail from "../../images/mail.svg";

interface HeaderModalProps {
  open: boolean;
  changeModalState: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const HeaderModal = ({open, changeModalState}: HeaderModalProps) => {
  const isPrizePage = useAppSelector((state) => state.main.isPrizePage);
  const isStandaloneServicesPage = useAppSelector((state) => state.main.isStandaloneServicesPage);

  const dispatch = useAppDispatch();

  const disableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(false))
    },
    [dispatch],
  );

  const enableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(false))
    },
    [dispatch],
  );

  const enablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(true));
      dispatch(changeNewsStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const enableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeNewsStatus(false));
    },
    [dispatch],
  );

  const closePages = useCallback(
    () => {
      disableNewsStatus();
      disablePrizeStatus();
      disableStandaloneServicesStatus();
    },
    [dispatch],
  );


  const cleanHash = () => {
    location.hash = ''
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={changeModalState}
      TransitionComponent={Transition}
    >
      <Box className={styles.modal_box}>
        <div className={styles.logos}>
          <img className={clsx(styles.logo_white, styles.logo)} src={logo_white} alt=""/>
          <div className={styles.close_flex}>
            <a href="mailto:emfc-kbr@mail.ru">
              <img src={mail} alt="mail"/>
            </a>
            {/* <a className={styles.video_icon} href="https://t.me/Duq_07" target="__blank">
                <svg fill="none" width="800px" height="800px" viewBox="-4 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5 13.219l6.844-3.938c0.906-0.531 1.656-0.156 1.656 0.938v11.625c0 1.063-0.75 1.5-1.656 0.969l-6.844-3.969v2.938c0 1.094-0.875 1.969-1.969 1.969h-11.625c-1.063 0-1.906-0.875-1.906-1.969v-11.594c0-1.094 0.844-1.938 1.906-1.938h11.625c1.094 0 1.969 0.844 1.969 1.938v3.031z"></path>
                </svg>            
              </a> */}
            <a href="tel:+78663040000">
              <img src={tel} alt="tel"/>
            </a>
            <img onClick={changeModalState} src={close} alt="bar"/>
          </div>
        </div>
        <div
          className={clsx(styles.deep_menu)}
          onClick={changeModalState}>
          <a
            onClick={closePages}
            href="#service_rates"
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Тарифы</button>
          </a>
          <a
            onClick={closePages}
            href="#team"
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Команда</button>
          </a>
          <a
            onClick={enableNewsStatus}
            href="#news"
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Новости</button>
          </a>
          <a
            onClick={closePages}
            href="#contacts"
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Контакты</button>
          </a>
          {!isPrizePage && (<a
            onClick={enablePrizeStatus}
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Розыгрыши</button>
          </a>)}
          {!isStandaloneServicesPage && (<a
            onClick={enableStandaloneServicesStatus}
            className={clsx(styles.deep_menu_link)}
          >
            <button className="text_lg">Платные услуги</button>
          </a>)}
          <a
            href="#contacts"
            className={clsx(styles.deep_menu_link, styles.quiz_btn)}
          >
            <button className={clsx(styles.btn_danger, "text_lg")}>Связаться</button>
          </a>
        </div>
      </Box>
    </Dialog>
  );
};

export default HeaderModal;
