import {forwardRef, memo, useCallback} from "react";
import styles from "./Modals.module.css";
import {Box, Dialog, Slide} from "@mui/material";
import close_logo from '../../images/close_logo.png';
import clsx from "clsx";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {changeModalStatus} from "../../redux/models/modal";
import {TransitionProps} from "@mui/material/transitions";
import ContactDark from "../ContactDark/ContactDark";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modals() {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector((state) => state.modal.isOpen)
  const meta = useAppSelector((state) => state.modal.meta)

  const handleCloseModal = useCallback(
      () => {
        dispatch(changeModalStatus({
          isOpen: false
        }))
      },
      [dispatch],
  );

  return meta ? (
      <Dialog
          fullScreen
          open={isModalOpen}
          onClose={handleCloseModal}
          TransitionComponent={Transition}
      >
        <div className={styles.back_action} onClick={handleCloseModal}/>
        <Box className={styles.modals_box}>
          <div className={styles.modals_box_field}>
            <div className={styles.modals_box_header}>
              <h3 className="h3">{meta.title}</h3>
              <button onClick={handleCloseModal}><img src={close_logo} alt="close"/></button>
            </div>
            <div className={styles.modals_box_info}>
              <button className='h2_des'>{meta.subTitle[0]}</button>
              <button className='h2_des'>{meta.subTitle[1]}</button>
              <button className={clsx(styles.modals_box_info_btn3, "h2_des")}>{meta.subTitle[2]}</button>
            </div>
            <div className={styles.modals_box_card_container}>
              <div className={styles.modals_box_card}>
                {meta.cards.map((item) => (
                    <div className={clsx(styles.modals_box_card_box, styles.modals_box_card_item1)} key={item.title}>
                      <div className={styles.modals_box_card_item1_title}>
                        <h4 className="h6">{item.title}</h4>
                        <span className={styles.card_badge}>{item.badge}</span>
                      </div>
                      <p className="text_small">{item.text}</p>
                    </div>
                ))}
              </div>
            </div>
            <ContactDark/>
          </div>
        </Box>
      </Dialog>
  ) : <></>
}

export default memo(Modals);