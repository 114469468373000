import {memo, useCallback, useState} from "react";
import styles from "./Header.module.css";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  changeMainStatus,
  changeNewsStatus,
  changePrizesStatus,
  changeStandaloneServicesStatus
} from "../../redux/models/main";
import clsx from "clsx";
import logo from "../../images/logo.svg";
import tel from "../../images/tel.svg";
import mail from "../../images/mail.svg";
import HeaderModal from "./HeaderModal";
import bar from "../../images/bar.svg";

function Header() {
  const isMainPage = useAppSelector((state) => state.main.isMainPage);
  const isPrizePage = useAppSelector((state) => state.main.isPrizePage);
  const isStandaloneServicesPage = useAppSelector((state) => state.main.isStandaloneServicesPage);

  const dispatch = useAppDispatch();
  const [modelOpen, setModalOpen] = useState(false);

  const changeModalState = useCallback(
    () => {
      location.hash = ''

      setModalOpen((pref) => !pref);
    },
    [],
  );

  const disableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(false))
    },
    [dispatch],
  );

  const enableNewsStatus = useCallback(
    () => {
      dispatch(changeNewsStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(false))
    },
    [dispatch],
  );

  const enablePrizeStatus = useCallback(
    () => {
      dispatch(changePrizesStatus(true));
      dispatch(changeNewsStatus(false));
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const disableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(false))
    },
    [dispatch],
  );

  const enableStandaloneServicesStatus = useCallback(
    () => {
      dispatch(changeStandaloneServicesStatus(true));
      dispatch(changePrizesStatus(false));
      dispatch(changeNewsStatus(false));
    },
    [dispatch],
  );

  const closePages = useCallback(
    () => {
      disableNewsStatus();
      disablePrizeStatus();
      disableStandaloneServicesStatus();
    },
    [dispatch],
  );

  const handleChangePageStatus = useCallback(
    () => {
      closePages();
      dispatch(changeMainStatus(true));
    },
    [closePages, dispatch],
  );

  const cleanHash = () => {
    location.hash = ''
  }

  return (
    <div id="latest_news" className={styles.header}>
      <div id="main_input" className={styles.flex_header}>
        <img src={logo} className={styles.logo} alt="logo"
             onClick={handleChangePageStatus}/>
        <div className={styles.logos}>
          <a href="mailto:emfc-kbr@mail.ru">
            <img src={mail} alt="mail"/>
          </a>
          <a href="tel:+78663040000">
            <img src={tel} alt="tel"/>
          </a>
          <img onClick={changeModalState} src={bar} alt="bar"/>
        </div>
        <div className={styles.deep_menu} onClick={cleanHash}>
          <a href="#team" onClick={closePages} className={clsx("text", styles.deep_menu_link)}>
            <button className="text">Команда</button>
          </a>
          {isMainPage && <a href="#about" onClick={closePages} className={clsx("text", styles.deep_menu_link)}>
            <button className="text">О нас</button>
          </a>}
          {!isMainPage &&
            <a href="#service_rates" onClick={closePages} className={clsx("text", styles.deep_menu_link)}>
              <button className="text">Tарифы</button>
            </a>}
          <a href="#news" onClick={enableNewsStatus} className={clsx("text", styles.deep_menu_link)}>
            <button className="text">Новости</button>
          </a>
          <a href="#contacts" onClick={closePages} className={clsx("text", styles.deep_menu_link)}>
            <button className="text">Контакты</button>
          </a>
          {!isPrizePage && <a href="#prize" onClick={enablePrizeStatus} className={clsx("text", styles.deep_menu_link)}>
            <button className="text">Розыгрыши</button>
          </a>}
          {!isStandaloneServicesPage && (
            <a onClick={enableStandaloneServicesStatus} className={clsx("text", styles.deep_menu_link)}>
              <button className="text">Платные услуги</button>
            </a>)}
        </div>

        <div className={clsx(styles.flex_info, "text")}>
          <a href="tel:+78663040000">
            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="15"/>
              <path
                d="M8.70824 13.5804C10.3375 17.131 13.2366 19.954 16.841 21.4844L17.4187 21.742C18.7406 22.3313 20.2945 21.8835 21.1008 20.6811L21.8558 19.5551C22.1013 19.189 22.0265 18.6959 21.6836 18.419L19.123 16.3521C18.7469 16.0485 18.1939 16.1192 17.9061 16.5077L17.114 17.5772C15.0814 16.574 13.4312 14.9228 12.4285 12.8889L13.4974 12.0964C13.8856 11.8085 13.9563 11.2551 13.6529 10.8788L11.5871 8.31659C11.3105 7.97353 10.8178 7.8987 10.4519 8.14417L9.31877 8.90442C8.10953 9.71572 7.66457 11.2834 8.26711 12.6095L8.70758 13.579L8.70824 13.5804Z"
              />
            </svg>
            <span className="text">+ 7 (8663) 04-00-00</span>
          </a>
        </div>
      </div>
      <HeaderModal open={modelOpen} changeModalState={changeModalState}/>
    </div>
  )
}

export default memo(Header);