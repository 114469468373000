import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from "./Services.module.css";
import clsx from "clsx";
import {InputBase, Paper} from "@mui/material";
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import {
  servicesForIndividuals,
  servicesForLegals,
  ServiceData,
  individualCards,
  legalCards
} from "../../utils";
import {CardsIndividual, CardsLegal} from '../../utils';
import {useAppDispatch} from '../../redux/hooks';
import {changeEmailModalStatus} from '../../redux/models/modal';
import {changeActiveCard} from '../../redux/models/main';

const ENTER_KEY = 13;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `none`,
  marginBottom: "25px",
  '&:not(:last-child)': {
    borderBottom: "none",
  },
  '&:before': {
    display: 'none',
  },
  padding: "0",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    className={styles.sumIcons}
    expandIcon={<AddCircleIcon sx={{fontSize: '2rem', borderRadius: "35px"}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'transparent'
      : 'transparent',
  '& .MuiAccordionSummary-expandIconWrapper': {
    margin: 'auto 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  "@media screen and (max-width: 480px)": {
    justifyContent: "start",
    alignItems: "start",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: "36px 30px",
  background: "#fff",
  marginBottom: "5px",
  border: "none",
  borderRadius: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media screen and (max-width: 480px)": {
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px",
  },
}));

const Services = ({isIndividuals, filterCard}: {
  isIndividuals: boolean;
  filterCard?: (CardsIndividual | CardsLegal)
}) => {
  const [expanded, setExpanded] = React.useState<string>();
  const dispatch = useAppDispatch();

  const servicePicker = isIndividuals ? servicesForIndividuals : servicesForLegals;
  const cards = isIndividuals ? individualCards : legalCards;

  const [filterText, setFilterText] = useState("");
  const [filteredArray, setFilteredArray] = useState<ServiceData[]>([]);

  const prefix = isIndividuals ? 'individual' : 'service';
  const handleChangeFilterText = useCallback((e: any) => {
    e.preventDefault();

    setFilterText(e.target.value.trim());
  }, []);

  const handleFilterArray = useCallback((e?: any) => {
    e?.preventDefault();
    setFilteredArray(servicePicker.reduce((accum: ServiceData[], item) => {
      const isInCard = (filterCard && item.forCards.includes(filterCard)) ?? true;
      if (item.title.toLocaleLowerCase().includes(filterText.toLowerCase().trim()) && isInCard) {
        return [...accum, item];
      }

      return accum;
    }, []));

  }, [setFilteredArray, servicePicker, filterText, filterCard])

  const handlePressEnter = useCallback((e: any) => {
    if (e.which === ENTER_KEY) {
      handleFilterArray()
    }
  }, [handleFilterArray]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded('');

      setTimeout(() => {
        window.location.href = `${window.location.origin}#${panel}`;
        setExpanded(newExpanded ? panel : '');
      }, 500)
    };

  const handleOpenModal = useCallback(
    (title: string) => {
      dispatch(changeEmailModalStatus({
        isOpenEmail: true,
        metaEmail: {title, isIndividuals},
      }))
    },
    [dispatch],
  );

  const getServices = (serviceCards: (CardsIndividual | CardsLegal)[]) => serviceCards.map((serviceCard) => (cards as any).find((card: any) => card.name === serviceCard))

  const handleSelectCard = (card: CardsIndividual | CardsLegal) => (event: React.SyntheticEvent) => {
    event.stopPropagation();

    dispatch(changeActiveCard(card))
    window.location.href = `${window.location.origin}#${card}`;
  }

  useEffect(() => {
    handleFilterArray();
  }, [filterCard])

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      setExpanded(hash.slice(1))
    }
  }, [])

  // useEffect(() => {
  //   if (expanded) {
  //     window.location.href = `${window.location.origin}#${expanded}`;
  //   }
  // }, [expanded, window.location.href, window?.location?.origin])

  return (
    <div className={styles.services_container}>
      <div className={styles.services_top}>
        <h1 className={clsx("h2_secondary", styles.services_title_secondary)}>
          {filteredArray?.length} бесплатных услуг по подписке {isIndividuals ? 'для граждан' : 'для бизнеса'}
        </h1>
      </div>
      <div className={styles.services_header}>
        <h1 className={clsx("h2_secondary", styles.services_title)}>Выбери услугу</h1>
        <div className={styles.search_input}>
          <Paper
            component="form"
            sx={{display: 'flex', alignItems: 'center', maxWidth: "400px", width: "100%"}}
            className={styles.paper}
          >
            <InputBase
              sx={{ml: 3, mr: 3, flex: 1}}
              placeholder="Поиск..."

              className="text"
              onChange={handleChangeFilterText}
              onKeyDown={handlePressEnter}
            />
            <button
              className={styles.search_btn}
              onClick={handleFilterArray}
            >Искать
            </button>
          </Paper>
        </div>
      </div>
      <div className={styles.accordion}>
        {filteredArray.map(service => (
          <Accordion
            expanded={expanded === `${prefix}-${service.id}`}
            onChange={handleChange(`${prefix}-${service.id}`)}
            key={service.id}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <div className={styles.accordion_title_wrapper}>
                <span
                  className={styles.anchor}
                  id={`${prefix}-${service.id}`}
                />
                <Typography className={styles.accordion_title}>{service.title}</Typography>
                {/*<span className={styles.accordion_title_price}>*/}
                {/*  {service.price} {getServices(service.forCards).map((card) =>*/}
                {/*  <span key={card.title} className={clsx(styles.accordion_title_price_card, card.name)}*/}
                {/*        onClick={handleSelectCard(card.name)}>*/}
                {/*      {card.title}*/}
                {/*    </span>*/}
                {/*)}*/}
                {/*</span>*/}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography className={styles.accordion_options}>
                  {service.description}
                </Typography>
                <div className={styles.accordion_list}>
                  {service.options?.map((option) => (
                    <div key={'option_' + option}>
                      <BoltIcon sx={{fontSize: '2rem', borderRadius: "35px"}}/>
                      <Typography>{option}</Typography>
                    </div>))}
                </div>
                <div className={styles.accordion_tags}>
                  {service.tags?.map((tag, index) => (
                    <span key={'tag_' + tag}
                          className={styles.accordion_options}>{`#${tag}${(service.tags.length > index + 1) ? ',' : '.'}`}</span>
                  ))}
                </div>
              </div>
              <button onClick={handleOpenModal.bind(null, service.title)}
                      className={clsx("text_small", styles.price_div)}>
                Получить услугу
              </button>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default memo(Services);
