import {memo} from "react";
import styles from "./Participate.module.css";
import clsx from "clsx";
import instagram_icon from '../../../../images/instagram-icon.svg'
import youtube_icon from '../../../../images/youtube-icon.svg'
import telegram_icon from '../../../../images/telegram-icon.svg'
import whatsapp_icon from '../../../../images/whatsapp-icon.svg'
import presentIcon from "../../../../images/prizeIcon.svg";

type TParticipateButton = {
  title?: string;
  icon?: string;
  link?: string;
  target?: '_blank';
}

type TParticipate = {
  count: string;
  title: string;
  buttons: TParticipateButton[];
}

const participates: TParticipate[] = [
  {
    count: '1',
    title: 'Оформите абонентскую карту',
    buttons: [{
      title: 'Оформить карту',
    }]
  },
  {
    count: '1.1',
    title: 'Смотрите инструкцию по оплате',
    buttons: [{
      title: 'Смотреть инструкцию',
      link: 'https://youtu.be/TfBYC7-SziA'
    }]
  },
  {
    count: '2',
    title: 'Заполните заявку',
    buttons: [{
      title: 'Оставить заявку',
      link: 'https://forms.gle/Am7uTViR616abqvh9'
    }]
  },
  {
    count: '3',
    title: 'Следите за розыгрышем в прямом эфире \n' +
      'в наших социальных сетях',
    buttons: [{
      icon: instagram_icon,
      link: 'https://www.instagram.com/emfc.kbr?igsh=MThzMTNzNTZhOGVrYw==',
      target: '_blank'
    }, {
      icon: youtube_icon,
      link: 'https://www.youtube.com/@EMFC-qb9ji/streams',
      target: '_blank'
    }, {
      icon: telegram_icon,
      link: 'https://t.me/emfckbr07',
      target: '_blank'
    }, {
      icon: whatsapp_icon,
      link: 'https://chat.whatsapp.com/Cg8InPFDXl8ABHS0NFgnqx',
      target: '_blank'
    },]
  },
]

function Participate() {

  return (
    <div className={styles.container}>
      <p className={clsx(styles.title, "h2")}>Как принять участие</p>
      <img className={styles.text_icon} src={presentIcon} alt="presentIcon"/>

      <div className={styles.cards}>
        {participates.map((item) => (
          <div key={item.title} className={styles.card}>
            <span className={styles.count}>{item.count}</span>
            <p className={clsx('h5')}>{item.title}</p>
            <div className={styles.buttons}>
              {item.buttons.map((button) => button.link && (
                <a key={button.link} href={button.link} target={button.target}>
                  {
                    button.icon &&
                    <img className={styles.icon} src={button.icon} alt=""/>
                  }
                  {button.title &&
                    <p className={clsx(styles.button, "btn_title")}>{button.title}</p>
                  }
                </a>
              ))}
            </div>
          </div>))}
      </div>
      <p className={styles.info}>
        * Instagram - признана экстремистской организацией, ее деятельность в России запрещена.
      </p>
    </div>
  )
}

export default memo(Participate);