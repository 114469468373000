export interface StandaloneServicesData {
  id: number;
  title: string;
  services: { id: number, text: string, price?: string }[];
}

export const standaloneServicesWithTitle: StandaloneServicesData[] = [
  {
    "services": [
      {
        "text": "консультация юриста и анализ возникшей ситуации с изучением всех представленных документов",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "оценка качества и количества имеющихся доказательств в материалах дела",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "изучение всех обстоятельств дела, имеющих важность для выработки позиции в конкретном споре",
        "price": "от 1500 руб",
        "id": 2
      },
      {
        "text": "подбор норм законодательства, доказывающих правомерность в конкретном споре",
        "price": "от 1500 руб",
        "id": 3
      },
      {
        "text": "анализ перспективы разрешения спора в досудебном порядке",
        "price": "от 1500 руб",
        "id": 4
      },
      {
        "text": "составление правового заключения",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "выезд юриста для проведения переговоров",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "досудебная и претензионная работа",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "составление и направление претензий",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "составление и направление запросов",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "составление и направление жалоб",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "проведение экспертиз",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "заключение мировых соглашений",
        "price": "от 10000 руб",
        "id": 12
      }
    ],
    "title": "цены на услуги юриста по досудебному урегулирование споров",
    "id": 0
  },
  {
    "services": [
      {
        "text": "консультация юриста и правовой анализ возникшей ситуации с изучением всех представленных документов",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "оценка качества и количества имеющихся доказательств в материалах дела",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "изучение всех обстоятельств дела, имеющих важность для выработки позиции в конкретном деле",
        "price": "от 1500 руб",
        "id": 2
      },
      {
        "text": "подбор норм законодательства, доказывающих правомерность в конкретном деле",
        "price": "от 1500 руб",
        "id": 3
      },
      {
        "text": "анализ и перспективы разрешения спора в судебном порядке",
        "price": "от 1500 руб",
        "id": 4
      },
      {
        "text": "подготовка искового заявления",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "подача искового заявления",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "составление отзыва на исковое заявление",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "составление апелляционной, кассационной жалобы",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "составление надзорной жалобы",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "составление отзыва на апелляционную, кассационную, надзорную жалобы",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "составление мирового соглашения",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "составление иных процессуальных документов (заявлений, ходатайств, возражений)",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "обжалование судебных приказов",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "организация проведения судебной экспертизы",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "разовые участия в судебных заседаниях",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "представление интересов клиента в суде общей юрисдикции,",
        "price": "от 30000 руб",
        "id": 16
      },
      {
        "text": "обжалование и представление интересов клиента в суде апелляционной инстанции",
        "price": "от 30000 руб",
        "id": 17
      },
      {
        "text": "обжалование и представление интересов клиента в суде кассационной и надзорной инстанции",
        "price": "от 30000 руб",
        "id": 18
      },
      {
        "text": "представление интересов клиента в иных учреждениях",
        "price": "от 30000 руб",
        "id": 19
      },
      {
        "text": "представление интересов клиента в судах регионов рф",
        "price": "от 50000 руб",
        "id": 20
      },
      {
        "text": "защита прав клиента в суде на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 21
      },
      {
        "text": "получение судебного решения",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "получение исполнительного листа",
        "price": "от 10000 руб",
        "id": 23
      }
    ],
    "title": "цены на судебное представительство",
    "id": 1
  },
  {
    "services": [
      {
        "text": "консультация юриста по арбитражным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "первичное изучение документации по арбитражному спору",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "составление претензии по арбитражному спору",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "составление ответа на претензию по арбитражному спору",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "составление искового заявления по арбитражному спору",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "составление отзыва на исковое заявление по арбитражному спору",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "составление апелляционной, кассационной жалоб по арбитражным спорам",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "составление надзорной жалобы по арбитражному спору",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "составление отзыва на апелляционную, кассационную, надзорную жалобы  по арбитражному спору",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "составление иных процессуальных документов (заявлений, возражений, ходатайств) по арбитражным спорам",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "расторжение договора купли-продажи, аренды, поставки, подряда и взыскание долга в арбитражном суде",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "взыскание денежных средств, неустойки и процентов за пользование чужими денежными средствами, убытков в арбитражном суде",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "обжалование решений государственных органов в арбитражном суде",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "признание договора в части или полностью недействительным в арбитражном суде",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "признание права собственности на коммерческое помещение в арбитражном суде",
        "price": "от 30000 руб",
        "id": 14
      },
      {
        "text": "истребование коммерческого имущества из чужого незаконного владения через арбитражный суд",
        "price": "от 30000 руб",
        "id": 15
      },
      {
        "text": "возмещение ущерба в арбитражном суде",
        "price": "от 30000 руб",
        "id": 16
      },
      {
        "text": "взыскание неосновательного обогащения в арбитражном суде",
        "price": "от 30000 руб",
        "id": 17
      },
      {
        "text": "разблокировка счета по 115-фз",
        "price": "от 30000 руб",
        "id": 18
      },
      {
        "text": "защита интеллектуальной собственности и авторских прав в арбитражном суде",
        "price": "от 30000 руб",
        "id": 19
      },
      {
        "text": "антимонопольные споры в арбитражном суде",
        "price": "от 30000 руб",
        "id": 20
      },
      {
        "text": "защита деловой репутации в арбитражном суде",
        "price": "от 30000 руб",
        "id": 21
      },
      {
        "text": "доменные споры в арбитражном суде",
        "price": "от 30000 руб",
        "id": 22
      },
      {
        "text": "споры с моэк/моэск в арбитражном суде",
        "price": "от 30000 руб",
        "id": 23
      },
      {
        "text": "споры с нефтегазовыми компаниями в арбитражном суде",
        "price": "от 30000 руб",
        "id": 24
      },
      {
        "text": "споры с банковскими и страховыми компаниями",
        "price": "от 30000 руб",
        "id": 25
      },
      {
        "text": "споры по государственным контрактам в арбитражном суде",
        "price": "от 30000 руб",
        "id": 26
      },
      {
        "text": "строительные споры в арбитражном суде",
        "price": "от 30000 руб",
        "id": 27
      },
      {
        "text": "оспаривание включения в рнп в арбитражном суде",
        "price": "от 50000 руб",
        "id": 28
      },
      {
        "text": "оспаривание действий заказчика по 44 и 223 фз",
        "price": "от 50000 руб",
        "id": 29
      },
      {
        "text": "организация проведения судебной экспертизы",
        "price": "от 10000 руб",
        "id": 30
      },
      {
        "text": "разовые участия в судебных заседаниях по арбитражным спорам",
        "price": "от 15000 руб",
        "id": 31
      },
      {
        "text": "ведение дел в арбитражном суде первой инстанции",
        "price": "от 60000 руб",
        "id": 32
      },
      {
        "text": "ведение дел в арбитражных судах апелляционной и кассационной инстанциях",
        "price": "от 60000 руб",
        "id": 33
      },
      {
        "text": "ведение дел в арбитражном суде надзорной инстанции",
        "price": "от 60000 руб",
        "id": 34
      },
      {
        "text": "международный арбитраж и трансграничные споры",
        "price": "от 300000 руб",
        "id": 35
      }
    ],
    "title": "цены на услуги юриста по арбитражным спорам",
    "id": 2
  },
  {
    "services": [
      {
        "text": "консультация автоюриста",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "анализ возникшей ситуации с изучением всех обстоятельств дела о дтп",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "выработка стратегии и тактики в защите законных прав и интересов клиента",
        "price": "от 1500 руб",
        "id": 2
      },
      {
        "text": "составление автоюристом жалоб и иных процессуальных документов (заявлений, жалоб, ходатайств)",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "выезд автоюриста для проведения переговоров",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "споры с гибдд",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "споры с дорожными и коммунальными службами",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "споры с автосалонами",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "споры с каршерингом",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "снятие запрета на регистрационные действия с автомобилем",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "обжалование эвакуации автомобиля",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "оспаривание штрафов",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "взыскание денежных средств за причиненный ущерб с виновника дтп",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "выезд на встречную полосу",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "превышение скорости на 60 км/час",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "перевозка крупногабаритных грузов",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "оставление места дтп",
        "price": "от 20000 руб",
        "id": 16
      },
      {
        "text": "отказ от медицинского освидетельствования",
        "price": "от 20000 руб",
        "id": 17
      },
      {
        "text": "наезд на пешехода",
        "price": "от 20000 руб",
        "id": 18
      },
      {
        "text": "смертельный исход при дтп",
        "price": "от 20000 руб",
        "id": 19
      },
      {
        "text": "споры со страховой компанией (каско, осаго)",
        "price": "от 20000 руб",
        "id": 20
      },
      {
        "text": "защита прав граждан во время административного задержания, при применении других мер административного принуждения",
        "price": "от 20000 руб",
        "id": 21
      },
      {
        "text": "споры о лишении водительских прав",
        "price": "от 20000 руб",
        "id": 22
      },
      {
        "text": "представление интересов клиента в суде без его присутствия (1 заседание)",
        "price": "от 10000 руб",
        "id": 23
      },
      {
        "text": "участие автоюриста в судах апелляционной, кассационной, надзорной инстанциях",
        "price": "от 30000 руб",
        "id": 24
      },
      {
        "text": "защита прав клиента в суде на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 25
      }
    ],
    "title": "цены на услуги автоюриста",
    "id": 3
  },
  {
    "services": [
      {
        "text": "консультация юриста по административным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "анализ возникшей ситуации с изучением всех обстоятельств по административному делу",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "выработка стратегии и тактики в защите законных прав и интересов клиента по административному делу",
        "price": "от 1500 руб",
        "id": 2
      },
      {
        "text": "составление и направление различных процессуальных  документов (объяснения, ходатайства, жалобы и др.)",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "защита прав и законных интересов организаций при производстве по делам об административных правонарушениях",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "защита прав граждан во время административного задержания, при применении других мер административного принуждения",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "обжалование судебных решений по административным делам",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "обжалование постановлений и решений органов государственной власти",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "обжалование действий и бездействий должностных лиц органов государственной власти",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "представление интересов организаций на стадии рассмотрения вопроса о возбуждении административного производства, при производстве проверки",
        "price": "от 30000 руб",
        "id": 9
      },
      {
        "text": "представление интересов клиента в суде без его присутствия по административному делу (1 заседание)",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "представление интересов в суде по административным спорам (делам) для физических лиц",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "представление интересов в суде по административным спорам (делам) для юридических лиц",
        "price": "от 50000 руб",
        "id": 12
      },
      {
        "text": "участие юриста в апелляционной, кассационной, надзорной инстанциях по административным делам",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "представление интересов граждан и организаций в службе судебных приставов на стадии исполнительного производства по административным делам",
        "price": "от 30000 руб",
        "id": 14
      }
    ],
    "title": "цены на услуги юриста по административным делам",
    "id": 4
  },
  {
    "services": [
      {
        "text": "консультация юриста по наследственным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебное урегулирование наследственных споров",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "признание наследником",
        "price": "от 30000 руб",
        "id": 2
      },
      {
        "text": "раздел наследственного имущества",
        "price": "от 30000 руб",
        "id": 3
      },
      {
        "text": "признание завещания недействительным",
        "price": "от 30000 руб",
        "id": 4
      },
      {
        "text": "признание наследника недостойным и отстранение его от наследования",
        "price": "от 30000 руб",
        "id": 5
      },
      {
        "text": "установление факта принятия наследства",
        "price": "от 30000 руб",
        "id": 6
      },
      {
        "text": "признание права на обязательную долю в наследстве",
        "price": "от 30000 руб",
        "id": 7
      },
      {
        "text": "восстановление срока принятия наследства",
        "price": "от 30000 руб",
        "id": 8
      },
      {
        "text": "оспаривание свидетельства о праве на наследство",
        "price": "от 30000 руб",
        "id": 9
      },
      {
        "text": "отказ нотариуса в выдаче свидетельства о праве на наследство",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "определение порядка пользования наследственным имуществом",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "включение имущества в наследственную массу",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "разовые участия в судебных заседаниях по наследственным спорам",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции, апелляционной, кассационной, надзорной инстанциях",
        "price": "от 30000 руб",
        "id": 14
      },
      {
        "text": "представление интересов клиента в иных учреждениях по наследственным делам",
        "price": "от 30000 руб",
        "id": 15
      }
    ],
    "title": "цены на услуги юриста по наследственным спорам",
    "id": 5
  },
  {
    "services": [
      {
        "text": "консультация юриста по семейным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная (претензионная) работа по семейным спорам",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "расторжение брака через суд",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "бракоразводный процесс с иностранцем",
        "price": "от 50000 руб",
        "id": 3
      },
      {
        "text": "взыскание алиментов на несовершеннолетнего ребенка с дохода",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "взыскание алиментов на несовершеннолетнего ребенка в твердой денежной сумме",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "взыскание алиментов на содержание бывшего супруга",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "уменьшение размера алиментов",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "споры с органами опеки и попечительства",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "споры с образовательными учреждениями",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "споры с социальными службами",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "споры с ритуальными службами",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "споры по декретным вопросам",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "споры по брачному договору",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "раздел имущества супругов через суд",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "оценка совместно нажитой собственности",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "определение доли каждого из супругов в совместно нажитом имуществе",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "раздел долгов и кредитных обязательств",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "раздел счетов и вкладов в банке",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "ипотека при разводе",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "раздел квартиры купленной по дду",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "определение места жительства ребенка",
        "price": "от 10000 руб",
        "id": 21
      },
      {
        "text": "определение порядка общения с ребенком",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "лишение родительских прав",
        "price": "от 10000 руб",
        "id": 23
      },
      {
        "text": "восстановление родительских прав",
        "price": "от 10000 руб",
        "id": 24
      },
      {
        "text": "установление отцовства",
        "price": "от 10000 руб",
        "id": 25
      },
      {
        "text": "оспаривание отцовства",
        "price": "от 10000 руб",
        "id": 26
      },
      {
        "text": "усыновление (удочерение) ребенка",
        "price": "от 10000 руб",
        "id": 27
      },
      {
        "text": "юридическое сопровождение суррогатного материнства",
        "price": "от 10000 руб",
        "id": 28
      },
      {
        "text": "разовые участия в судебных заседаниях по семейным спорам",
        "price": "от 10000 руб",
        "id": 29
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по семейным спорам",
        "price": "от 30000 руб",
        "id": 30
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по семейным спорам",
        "price": "от 30000 руб",
        "id": 31
      },
      {
        "text": "защита прав клиента в суде по семейным спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 32
      }
    ],
    "title": "цены на юридические услуги адвоката семейным спорам",
    "id": 6
  },
  {
    "services": [
      {
        "text": "консультация юриста по трудовым спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная и претензионная работа по трудовым спорам",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "сбор доказательной базы",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "установление факта трудовых отношений",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "незаконное увольнение",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "восстановление на работе",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "взыскание с работодателя заработной платы",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "взыскание компенсации с работодателя за задержку заработной платы",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "взыскание компенсации с работодателя за травму на производстве",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "отмена приказа о дисциплинарном наказании",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "взыскание с работника материального ущерба",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "взыскание с работника задолженности",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "споры с пенсионным фондом (пфр)",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "споры по начислению пенсии",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "споры по охране труда",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "споры с трудовой инспекцией (гит)",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "представление интересов клиента в иных учреждениях",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "разовые участия в судебных заседаниях по трудовым спорам",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по трудовым спорам",
        "price": "от 30000 руб",
        "id": 18
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по трудовым спорам",
        "price": "от 30000 руб",
        "id": 19
      },
      {
        "text": "защита прав клиента в суде по трудовым спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 20
      }
    ],
    "title": "цены на услуги юриста по трудовым спорам",
    "id": 7
  },
  {
    "services": [
      {
        "text": "консультация юриста по жилищным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная (претензионная) работа",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "выселение",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "вселение",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "выписка",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "дарение",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "раздел имущества",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "споры с общей долевой собственностью",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "выделение доли в натуре",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "определение доли в недвижимости",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "признание права собственности на жилое помещение и приватизация через суд",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "определение порядка пользования жильем",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "раздел лицевых счетов",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "оспаривание кадастровой стоимости недвижимости",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "возмещение ущерба при затоплении",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "возмещение ущерба при пожаре",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "узаконивание перепланировки",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "признание сделки недействительной",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "расторжение договора в судебном порядке",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "признание гражданина не приобретшим права пользования помещением",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "обжалование решений собраний тсж, снт",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "обжалование решений гжи и иных государственных органов по жилищным спорам",
        "price": "от 10000 руб",
        "id": 21
      },
      {
        "text": "узаконивание реконструкции жилого фонда и иных объектов недвижимости",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "узаконивание самостроя на территории жилого фонда",
        "price": "от 10000 руб",
        "id": 23
      },
      {
        "text": "помощь в улучшении жилищных условий",
        "price": "от 10000 руб",
        "id": 24
      },
      {
        "text": "жалобы на жкх и споры с управляющей компанией",
        "price": "от 10000 руб",
        "id": 25
      },
      {
        "text": "обжалование судебных приказов по коммунальным услугам и капремонту",
        "price": "от 10000 руб",
        "id": 26
      },
      {
        "text": "разовые участия в судебных заседаниях по жилищным спорам",
        "price": "от 10000 руб",
        "id": 27
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по жилищным спорам",
        "price": "от 30000 руб",
        "id": 28
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по жилищным спорам",
        "price": "от 30000 руб",
        "id": 29
      },
      {
        "text": "защита прав клиента в суде по жилищным спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 30
      }
    ],
    "title": "цены на услуги юриста по жилищным спорам",
    "id": 8
  },
  {
    "services": [
      {
        "text": "консультация юриста по недвижимости",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная (претензионная) работа по спорам с недвижимостью",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "сбор доказательной базы",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "устранение недостатков, допущенных при строительстве",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "оспаривание увеличения площади",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "оспаривание кадастровой стоимости недвижимости",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "расторжение договора долевого участия (дду), предварительного договора долевого участия (пдду),",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "взыскание неустойки с застройщика, штрафа, морального вреда, судебных расходов",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "признание в суде права собственности на объект недвижимости",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "признание договора долевого участия полностью или в части недействительным",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "юридическое сопровождение сделок с недвижимостью",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "расторжение договоров по сделкам с недвижимостью",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "признание сделок с недвижимостью недействительными",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "оспаривание договоров дарения недвижимости",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "организация проведения судебной экспертизы",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "споры с дги и росреестром",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "отмена приватизации недвижимости в суде",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "узаконивание самостроя и иных объектов недвижимости",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "узаконивание перепланировки помещений",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "изменение целевого назначения помещений",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "снятие запрета на регистрационные действия с недвижимостью",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "разовые участия в судебных заседаниях по спорам с недвижимостью",
        "price": "от 10000 руб",
        "id": 21
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по спорам с недвижимостью",
        "price": "от 30000 руб",
        "id": 22
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по спорам с недвижимостью",
        "price": "от 30000 руб",
        "id": 23
      },
      {
        "text": "защита прав клиента в суде по спорам с недвижимостью на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 24
      }
    ],
    "title": "цены на услуги юриста по недвижимости",
    "id": 9
  },
  {
    "services": [
      {
        "text": "консультация юриста по земельным спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "изучение документации по земельному спору",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "помощь юриста в досудебном урегулировании",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "установление границ земельного участка",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "признание акта согласования границ земельного участка недействительным",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "признание недействительными сведения о координатах характерных точек и исключение их из государственного кадастра недвижимости (гкн)",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "исправление кадастровой ошибки",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "оспаривание кадастровой стоимости земельного участка",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "определение порядка пользования земельным участком",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "признание права собственности на земельный участок в суде",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "определение доли в совместной собственности на дачное строение и земельный участок, выделение доли в натуре",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "оспаривание решения государственных органов по земельным спорам",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "обжалование отказа в регистрации права собственности на земельный участок или иной объект недвижимости",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "признание сделки с земельным участком недействительной",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "истребование земельного участка из чужого незаконного владения",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "освобождение земельного участка от ареста",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "признание права постоянного (бессрочного) пользования земельным участком",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "признание права собственности на частный дом",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "снос самовольно возведенной постройки",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "расторжение договора аренды земельного участка и  взыскание долга",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "установление сервитута",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "узаконивание самостроя и различных строений на земельном участке",
        "price": "от 10000 руб",
        "id": 21
      },
      {
        "text": "разовые участия в судебных заседаниях по земельным спорам",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по земельным спорам",
        "price": "от 30000 руб",
        "id": 23
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по земельным спорам",
        "price": "от 30000 руб",
        "id": 24
      },
      {
        "text": "защита прав клиента в суде по земельным спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 25
      }
    ],
    "title": "цены на услуги юриста по земельным спорам",
    "id": 10
  },
  {
    "services": [
      {
        "text": "консультация банковского юриста",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная и претензионная работа по кредитно-финансовым спорам",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "юридическая помощь при невыплате % по вкладам",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "юридическая помощь при не возврате вклада",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "юридическая помощь при спорах с мфо",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "юридическая помощь при спорах с коллекторами (услуга антиколлектор)",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "возврат средств от брокеров и инвестиционных компаний",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "юридическая помощь по чарджбеку",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "юридическая помощь по блокчейну",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "юридическая помощь поручителю по кредиту",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "юридическая помощь по криптовлюте",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "разблокировка счета в банке по 115-фз",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "возврат страховки по кредиту",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "увеличение срока погашения кредита (займа)",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "рассрочка (отсрочка) исполнения решения суда",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "реструктуризация платежа",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "списание части долга по кредиту",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "уменьшение суммы платежа по кредиту",
        "price": "от 10000 руб",
        "id": 17
      },
      {
        "text": "рефинансирование кредита в другой банк",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "признание кредитного договора полностью или в части недействительным",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "расторжение договора в судебном порядке",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "представление интересов клиента в иных учреждениях",
        "price": "от 10000 руб",
        "id": 21
      },
      {
        "text": "разовые участия в судебных заседаниях по банковским и кредитным спорам",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по банковским и кредитным спорам",
        "price": "от 30000 руб",
        "id": 23
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по банковским и кредитным спорам",
        "price": "от 30000 руб",
        "id": 24
      },
      {
        "text": "защита прав клиента в суде по банковским спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 25
      }
    ],
    "title": "цены на услуги банковского юриста по кредитным и финансовым спорам",
    "id": 11
  },
  {
    "services": [
      {
        "text": "консультация юриста по страховым спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "досудебная и претензионная работа по страховым спорам",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "составление и подача исковых заявлений для решения вопросов в судебном порядке по страховым спорам",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "подготовка всех необходимых документов, составление жалоб и ходатайств",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "экспертная оценка нанесенного ущерба имуществу и расчет суммы возмещения по страховому случаю",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "представление интересов клиента в суде и других инстанциях по страховым спорам",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "взыскание в судебном порядке денежных средств по осаго, каско",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "взыскание в судебном порядке денежных средств по страхованию имущества",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "участие юриста в судах апелляционной, кассационной, надзорной инстанциях",
        "price": "от 10000 руб",
        "id": 8
      }
    ],
    "title": "цены на услуги юриста по страховым спорам",
    "id": 12
  },
  {
    "services": [
      {
        "text": "консультация юриста по защите прав потребителей",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "составление жалобы в роспотребнадзор",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "составление досудебной претензии",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "расторжение договора об оказании услуг",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "расторжение договора о продаже товаров",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "возврат товара продавцу",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "возврат денег за неоказанную услугу или некачественный товар",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "исковая защита потребителя",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "подача иска о защите прав потребителя (мелкие товары, одежда, обувь и т.д.)",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "подача иска о защите прав потребителя (технически сложная продукция в т.ч. автомобили)",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "составление письменных возражений по иску потребителя",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "представление интересов клиента в иных учреждениях",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "разовые участия в судебных заседаниях по спорам о защите прав потребителя",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по спорам о защите прав потребителя",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по спорам о защите прав потребителя",
        "price": "от 30000 руб",
        "id": 14
      },
      {
        "text": "защита прав клиента в суде по спорам о защите прав потребителей на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 15
      }
    ],
    "title": "цены на услуги юриста по защите прав потребителей",
    "id": 13
  },
  {
    "services": [
      {
        "text": "консультация юриста по возврату долгов",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "составление досудебной претензии на возврат долга",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "подача иска в суд по вопросу взыскания задолженности",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "подача заявления на вынесение судебного приказа",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "взыскание долга по расписке",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "взыскание долга без расписки",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "взыскание долга по исполнительному листу",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "взыскание долга по кредиту",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "взыскание долга по договору",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "взыскание долга по векселю",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "взыскание долга в порядке регресса",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "взыскание долга с наследников",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "взыскание долга с контрагента",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "взыскание долга с поставщика",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "взыскание долга с генерального директора ооо",
        "price": "от 30000 руб",
        "id": 14
      },
      {
        "text": "взыскание долга с учредителя ооо",
        "price": "от 30000 руб",
        "id": 15
      },
      {
        "text": "взыскание дебиторской задолженности",
        "price": "от 30000 руб",
        "id": 16
      },
      {
        "text": "взыскание долгов с юридических лиц",
        "price": "от 30000 руб",
        "id": 17
      },
      {
        "text": "взыскание долгов с физических лиц",
        "price": "от 10000 руб",
        "id": 18
      },
      {
        "text": "взыскание долгов с самозанятых",
        "price": "от 10000 руб",
        "id": 19
      },
      {
        "text": "взыскание долгов с ип",
        "price": "от 10000 руб",
        "id": 20
      },
      {
        "text": "взыскание долгов в сфере жкх",
        "price": "от 30000 руб",
        "id": 21
      },
      {
        "text": "взыскание неустойки",
        "price": "от 10000 руб",
        "id": 22
      },
      {
        "text": "розыск активов и имущества должника в рф",
        "price": "от 50000 руб",
        "id": 23
      },
      {
        "text": "розыск активов и имущества должника за пределами рф",
        "price": "от 150000 руб",
        "id": 24
      },
      {
        "text": "представление интересов клиента в иных учреждениях",
        "price": "от 10000 руб",
        "id": 25
      },
      {
        "text": "взаимодействие с коллекторами",
        "price": "от 10000 руб",
        "id": 26
      },
      {
        "text": "разовые участия в судебных заседаниях по вопросу взыскания задолженности",
        "price": "от 10000 руб",
        "id": 27
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по взысканию задолженности",
        "price": "от 30000 руб",
        "id": 28
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по взысканию задолженности",
        "price": "от 30000 руб",
        "id": 29
      },
      {
        "text": "защита прав клиента в суде по на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 30
      }
    ],
    "title": "цены на услуги юриста по возврату долгов",
    "id": 14
  },
  {
    "services": [
      {
        "text": "консультация юриста по исполнительному производству",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "возбуждение исполнительного производства",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "получение исполнительного документа",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "предъявление исполнительного документа",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "повторное предъявление исполнительного документа",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "неисполнение решения суда",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "обжалование бездействия судебных приставов",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "обжалование незаконных действий судебных приставов",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "прекращение исполнительного производства",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "снятие ареста с имущества через суд",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "подача административного иска на действия должностных лиц фссп рф",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "представление интересов клиента в судебных инстанциях по исполнительному производству",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "сопровождение, оспаривание и приостановка торгов по исполнительному производству",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "комплексное юридическое сопровождение исполнительного производства",
        "price": "от 30000 руб",
        "id": 13
      }
    ],
    "title": "цены на услуги юриста по сопровождению исполнительного производства",
    "id": 15
  },
  {
    "services": [
      {
        "text": "консультация юриста по медицинским спорам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "анализ документации медицинского характера с правовой точки зрения",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "выработка стратегии и тактики в защите законных прав и интересов клиента по медицинскому спору",
        "price": "от 1500 руб",
        "id": 2
      },
      {
        "text": "определение перспективы предъявления иска в суд к учреждению здравоохранения",
        "price": "от 1500 руб",
        "id": 3
      },
      {
        "text": "проведение претензионной работы в досудебном порядке по медицинским спорам",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "представительство пациента в переговорах с руководством медицинских учреждений",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "организация проведения судмедэкспертизы",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "защита прав доверителя при причинении вреда его здоровью во время оказания медпомощи и в случае смерти пациента",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "составление процессуальных документов: жалоб, претензий, исковых заявлений, ходатайств",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "представление интересов клиента в иных учреждениях по медицинским спорам",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "споры по халатности врачей",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "споры по врачебным ошибкам",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "споры по нарушению врачебной тайны",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "споры со стоматологическими клиниками",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "споры с клиниками пластической хирургии",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "разовые участия в судебных заседаниях по медицинским спорам",
        "price": "от 10000 руб",
        "id": 15
      },
      {
        "text": "представление интересов клиента в судах общей юрисдикции по медицинским спорам",
        "price": "от 30000 руб",
        "id": 16
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях по медицинским спорам",
        "price": "от 30000 руб",
        "id": 17
      },
      {
        "text": "защита прав клиента в суде по медицинским спорам на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 18
      }
    ],
    "title": "цены на услуги юриста по медицине",
    "id": 16
  },
  {
    "services": [
      {
        "text": "консультация юриста по банкротству",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "банкротство физических лиц",
        "price": "от 50000 руб",
        "id": 1
      },
      {
        "text": "банкротство юридических лиц",
        "price": "от 150000 руб",
        "id": 2
      },
      {
        "text": "банкротство индивидуальных предпринимателей",
        "price": "от 100000 руб",
        "id": 3
      },
      {
        "text": "ликвидация фирм с долгами",
        "price": "от 100000 руб",
        "id": 4
      },
      {
        "text": "представление интересов клиента в суде по банкротству",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "представление интересов клиента в инстанциях по банкротству",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "оспаривание договоров в процедуре банкротства",
        "price": "от 50000 руб",
        "id": 7
      },
      {
        "text": "оспаривание сделок в процедуре банкротства",
        "price": "от 50000 руб",
        "id": 8
      },
      {
        "text": "обжалование действий конкурсного управляющего",
        "price": "от 50000 руб",
        "id": 9
      },
      {
        "text": "обжалование действий кредитора",
        "price": "от 50000 руб",
        "id": 10
      },
      {
        "text": "обжалование определений о банкротстве",
        "price": "от 50000 руб",
        "id": 11
      },
      {
        "text": "оспаривание оценки имущества",
        "price": "от 50000 руб",
        "id": 12
      },
      {
        "text": "оспаривание торгов при банкротстве",
        "price": "от 50000 руб",
        "id": 13
      }
    ],
    "title": "цены на услуги юриста по банкротству",
    "id": 17
  },
  {
    "services": [
      {
        "text": "составление исковых заявлений",
        "price": "от 10000 руб",
        "id": 0
      },
      {
        "text": "составление коллективного иска",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "составление отзывов и ходатайств",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "составление апелляционной жалобы",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "составление кассационной жалобы",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "составление возражений",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "составление претензий",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "составление заявлений в государственные органы",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "составление запросов",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "составление жалоб в надзорные инстанции",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "составление объяснений",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "составление контрактов",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "составление договоров и дополнительных соглашений",
        "price": "от 10000 руб",
        "id": 12
      },
      {
        "text": "составление деловых писем",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "составление актов выполненных работ",
        "price": "от 10000 руб",
        "id": 14
      }
    ],
    "title": "цены на помощь юриста в составлении и оформлении правовых документов",
    "id": 18
  },
  {
    "services": [
      {
        "text": "консультация юриста по всем правовым аспектам сделки, в т.ч. налоговым",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "проверка уже существующих документов, необходимых для купли-продажи имущества, на предмет законности",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "выявление наличия возможных ограничений на заключение сделок",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "проверка юридической чистоты сделки",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "сопровождение сделок с коммерческой, загородной, жилой и иными видами недвижимости",
        "price": "от 30000 руб",
        "id": 4
      },
      {
        "text": "сопровождение сделок с земельными участками",
        "price": "от 30000 руб",
        "id": 5
      },
      {
        "text": "сопровождение ипотечных сделок",
        "price": "от 30000 руб",
        "id": 6
      },
      {
        "text": "сопровождение сделок по купле-продаже доли в ооо",
        "price": "от 50000 руб",
        "id": 7
      },
      {
        "text": "сопровождение сделок по купле-продаже бизнеса",
        "price": "от 100000 руб",
        "id": 8
      },
      {
        "text": "сопровождение сделок по купле-продаже акций",
        "price": "от 100000 руб",
        "id": 9
      },
      {
        "text": "сопровождение сделок по выпуску облигаций",
        "price": "от 100000 руб",
        "id": 10
      },
      {
        "text": "сопровождение сделок по криптовалюте",
        "price": "от 100000 руб",
        "id": 11
      },
      {
        "text": "сопровождение сделок по купле-продаже предметов искусства",
        "price": "от 100000 руб",
        "id": 12
      }
    ],
    "title": "цены на юридическое сопровождение сделок",
    "id": 19
  },
  {
    "services": [
      {
        "text": "абонентское юридическое обслуживание организаций",
        "price": "от 50000 руб",
        "id": 0
      },
      {
        "text": "юридические консалтинговые услуги",
        "price": "от 50000 руб",
        "id": 1
      },
      {
        "text": "юридический аутсорсинг",
        "price": "от 50000 руб",
        "id": 2
      },
      {
        "text": "налаживание процессов бизнес-деятельности",
        "price": "от 50000 руб",
        "id": 3
      },
      {
        "text": "юридическое сопровождение новых проектов",
        "price": "от 50000 руб",
        "id": 4
      },
      {
        "text": "внутренний аудит и контроль за соблюдением действующего законодательства",
        "price": "от 50000 руб",
        "id": 5
      },
      {
        "text": "урегулирование споров в досудебном порядке",
        "price": "от 50000 руб",
        "id": 6
      },
      {
        "text": "судебное представительство и ведение дел в арбитраже",
        "price": "от 50000 руб",
        "id": 7
      },
      {
        "text": "составление процессуальных документов",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "проведение и оформление сделок",
        "price": "от 30000 руб",
        "id": 9
      },
      {
        "text": "аналитическая работа с договорами",
        "price": "от 10000 руб",
        "id": 10
      },
      {
        "text": "участие юриста в переговорах",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "взыскание дебиторской задолженности",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "проверка контрагентов",
        "price": "от 10000 руб",
        "id": 13
      },
      {
        "text": "регистрация бизнеса",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "открытие расчетного счета",
        "price": "от 1500 руб",
        "id": 15
      },
      {
        "text": "внесение изменений в учредительные документы",
        "price": "от 10000 руб",
        "id": 16
      },
      {
        "text": "закрытие и ликвидация юридических лиц (в т.ч. с долгами)",
        "price": "от 100000 руб",
        "id": 17
      },
      {
        "text": "реорганизация предприятий",
        "price": "от 100000 руб",
        "id": 18
      },
      {
        "text": "участие юриста при проверке правоохранительных органов",
        "price": "от 30000 руб",
        "id": 19
      },
      {
        "text": "личный юрист",
        "price": "от 100000 руб",
        "id": 20
      },
      {
        "text": "международное юридическое сопровождение и трансграничные споры компаний",
        "price": "от 300000 руб",
        "id": 21
      }
    ],
    "title": "цены на юридическое сопровождение компаний и физических лиц",
    "id": 20
  },
  {
    "services": [
      {
        "text": "посредничество и представительство в переговорах",
        "price": "от 10000 руб",
        "id": 0
      },
      {
        "text": "досудебное урегулирование споров",
        "price": "от 30000 руб",
        "id": 1
      },
      {
        "text": "судебная защита и представительство",
        "price": "от 30000 руб",
        "id": 2
      },
      {
        "text": "защита интеллектуальной собственности и авторских прав",
        "price": "от 30000 руб",
        "id": 3
      },
      {
        "text": "споры между действующими учредителями компании",
        "price": "от 50000 руб",
        "id": 4
      },
      {
        "text": "денежные споры",
        "price": "от 50000 руб",
        "id": 5
      },
      {
        "text": "споры между предприятиями",
        "price": "от 50000 руб",
        "id": 6
      },
      {
        "text": "споры между предпринимателями",
        "price": "от 50000 руб",
        "id": 7
      },
      {
        "text": "споры по контрактам",
        "price": "от 50000 руб",
        "id": 8
      },
      {
        "text": "споры с государственными органами",
        "price": "от 50000 руб",
        "id": 9
      },
      {
        "text": "сопровождение во время налоговых проверок",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "обжалование действий должностных лиц в судебном порядке",
        "price": "от 10000 руб",
        "id": 11
      },
      {
        "text": "реорганизация и ликвидация бизнеса",
        "price": "от 100000 руб",
        "id": 12
      },
      {
        "text": "работа с дебиторской задолженностью",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "контроль и помощь в проведении сделок",
        "price": "от 10000 руб",
        "id": 14
      },
      {
        "text": "разработка и внедрение схем защиты активов",
        "price": "от 100000 руб",
        "id": 15
      }
    ],
    "title": "цены на услуги юриста по защите бизнеса и собственности",
    "id": 21
  },
  {
    "services": [
      {
        "text": "консультация юриста по налоговому и таможенному законодательству",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "помощь при привлечении к административной, налоговой или уголовной ответственности за налоговые нарушения",
        "price": "от 10000 руб",
        "id": 1
      },
      {
        "text": "помощь при неправомерном отказе в возмещении ндс",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "помощь при получении уведомления о налоговой проверке",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "помощь при неправомерном доначислении налогов и штрафов",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "помощь при необходимости возврата из бюджета средств, излишне взысканных фнс, или излишне уплаченных налогоплательщиком",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "помощь при нарушении законности представителями фнс",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "помощь при необходимости взыскания убытков, причиненных налоговыми органами",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "анализ нарушений акта выездной налоговой проверки с выводами о перспективах разрешения налоговых споров",
        "price": "от 30000 руб",
        "id": 8
      },
      {
        "text": "подготовка возражений на акт налоговой проверки",
        "price": "от 30000 руб",
        "id": 9
      },
      {
        "text": "помощь при рассмотрении материалов налоговой проверки в налоговом органе",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "анализ нарушений решения налогового органа по результатам выездной налоговой проверки с выводами",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "подготовка апелляционной жалобы на решение налогового органа по результатам выездной налоговой проверки",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "анализ материалов внеплановой налоговой проверки и подготовка заключения",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "представительство в арбитражном суде в первой инстанции при спорах с фнс",
        "price": "от 50000 руб",
        "id": 14
      },
      {
        "text": "представление интересов клиента в арбитражном суде апелляционной, кассационной, надзорной инстанциях при спорах с фнс",
        "price": "от 50000 руб",
        "id": 15
      },
      {
        "text": "комплексное юридическое сопровождение налоговых проверок",
        "price": "от 50000 руб",
        "id": 16
      },
      {
        "text": "комплексное юридическое сопровождение внешнеэкономических сделок и урегулирование таможенных споров",
        "price": "от 50000 руб",
        "id": 17
      }
    ],
    "title": "цены на услуги юриста по налоговым спорам",
    "id": 22
  },
  {
    "services": [
      {
        "text": "консультация адвоката по уголовным делам",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "срочный выезд адвоката",
        "price": "от 20000 руб",
        "id": 1
      },
      {
        "text": "защита адвокатом на стадии дознания и при проведении доследственной проверки",
        "price": "от 30000 руб",
        "id": 2
      },
      {
        "text": "защита адвокатом на предварительном следствии",
        "price": "от 30000 руб",
        "id": 3
      },
      {
        "text": "защита адвокатом в суде первой инстанции",
        "price": "от 30000 руб",
        "id": 4
      },
      {
        "text": "защита адвокатом по уголовному делу \"под ключ\"",
        "price": "от 150000 руб",
        "id": 5
      },
      {
        "text": "подготовка адвокатом процессуальных документов",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "обжалование адвокатом меры пресечения",
        "price": "от 30000 руб",
        "id": 7
      },
      {
        "text": "обжалование адвокатом незаконных действий следователя",
        "price": "от 30000 руб",
        "id": 8
      },
      {
        "text": "помощь адвоката на допросе",
        "price": "от 30000 руб",
        "id": 9
      },
      {
        "text": "помощь адвоката при задержании",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "ознакомление адвоката с материалами уголовного дела",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "посещение адвокатом в сизо, ивс",
        "price": "от 30000 руб",
        "id": 12
      },
      {
        "text": "помощь в снятии судимости",
        "price": "от 30000 руб",
        "id": 13
      },
      {
        "text": "условно-досрочное освобождение",
        "price": "от 30000 руб",
        "id": 14
      },
      {
        "text": "замена неотбытой части наказания более мягким видом",
        "price": "от 30000 руб",
        "id": 15
      },
      {
        "text": "обжалование адвокатом возбуждения уголовного дела",
        "price": "от 30000 руб",
        "id": 16
      },
      {
        "text": "обжалование адвокатом отказа в возбуждении уголовного дела",
        "price": "от 30000 руб",
        "id": 17
      },
      {
        "text": "обжалование адвокатом действий сотрудников правоохранительных органов",
        "price": "от 30000 руб",
        "id": 18
      },
      {
        "text": "обжалование адвокатом приговора в суде апелляционной инстанции",
        "price": "от 50000 руб",
        "id": 19
      },
      {
        "text": "обжалование адвокатом приговора в суде кассационной инстанции",
        "price": "от 50000 руб",
        "id": 20
      },
      {
        "text": "обжалование адвокатом приговора в порядке надзора",
        "price": "от 50000 руб",
        "id": 21
      },
      {
        "text": "обжалование адвокатом в еспч уголовных дел",
        "price": "от 50000 руб",
        "id": 22
      }
    ],
    "title": "цены на услуги адвоката по уголовным делам",
    "id": 23
  },
  {
    "services": [
      {
        "text": "консультация профильного юриста с изучением всех представленных документов",
        "price": "от 1500 руб",
        "id": 0
      },
      {
        "text": "подбор норм законодательства и судебной практики, доказывающих правомерность в конкретном споре",
        "price": "от 1500 руб",
        "id": 1
      },
      {
        "text": "проведение переговоров юристом",
        "price": "от 10000 руб",
        "id": 2
      },
      {
        "text": "составление процессуальных документов",
        "price": "от 10000 руб",
        "id": 3
      },
      {
        "text": "претензионная работа и досудебное урегулирование спора",
        "price": "от 10000 руб",
        "id": 4
      },
      {
        "text": "организация проведения судебной экспертизы",
        "price": "от 10000 руб",
        "id": 5
      },
      {
        "text": "возмещение вреда в судебном порядке",
        "price": "от 10000 руб",
        "id": 6
      },
      {
        "text": "расторжение договоров в судебном порядке",
        "price": "от 10000 руб",
        "id": 7
      },
      {
        "text": "признание сделок недействительными",
        "price": "от 10000 руб",
        "id": 8
      },
      {
        "text": "разовые участия в судебных заседаниях",
        "price": "от 10000 руб",
        "id": 9
      },
      {
        "text": "представление интересов клиента в судах первой инстанции",
        "price": "от 30000 руб",
        "id": 10
      },
      {
        "text": "обжалование и представление интересов клиента в судах апелляционной/кассационной/надзорной инстанциях",
        "price": "от 30000 руб",
        "id": 11
      },
      {
        "text": "защита прав клиента в суде на всех стадиях судебного процесса (суд «под ключ»)",
        "price": "от 30000 руб",
        "id": 12
      }
    ],
    "title": "цены на услуги юрист по сферам права",
    "id": 24
  }
];

const regex = /(?<=\n|\t)([а-яА-ЯёЁ .,0-9-"(\)\/«»%:]+)(?<!&|\n|\s)/gm
