import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import main from './models/main';
import modal from  "./models/modal";
import news from './models/news';

export const store = configureStore({
  reducer: {
    main,
    modal,
    news,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
