import {memo} from "react";
import styles from "./MainBanner.module.css";

const ratio = 560 / 315;

const url = 'https://www.youtube.com/embed/AL2mAvfGLcQ?si=SSMtjv9uPEvFHlVr?controls=0'

function MainBanner() {
  return (
    <div className={styles.container}>
      <iframe className={styles.frame} width="100%" src={url} allowFullScreen>
      </iframe>
    </div>
  )
}

export default memo(MainBanner);